import client from '@/services/api/RemovifyServiceV2/config.js';

/**
 * Get the user organization
 * @param {number} organization_id
 * @returns {Promise}
 */
export const getGroups = (organization_id) => {
  return client.get(`/publishers/${organization_id}/groups/v2`);
};

/**
 * Create a new group
 * @param {number} organization_id
 * @param {object} data
 * @property {string} data.name
 * @returns {Promise}
 */
export const createGroup = (organization_id, data) => {
  return client.post(`/publishers/${organization_id}/groups/v2`, data);
};

/**
 * Update a group
 * @param {number} organization_id
 * @param {number} group_id
 * @param {object} data
 * @property {string} data.name
 * @returns {Promise}
 */
export const updateGroup = (organization_id, group_id, data) => {
  return client.put(
    `/publishers/${organization_id}/groups/v2/${group_id}`,
    data,
  );
};

/**
 * Delete a group
 * @param {number} organization_id
 * @param {number} group_id
 * @returns
 */
export const deleteGroup = (organization_id, group_id) => {
  return client.delete(`/publishers/${organization_id}/groups/v2/${group_id}`);
};

/**
 * attach or detach monitors to a group
 * @param {number} organization_id
 * @param {object} data
 * @property {number | null} data.destination_group_id null if ungrouped
 * @property {Array} data.monitors
 * @property {object} data.monitors[].monitor
 * @property {number} data.monitors[].monitor.url_id
 * @property {number} data.monitors[].monitor.group_id
 * @returns
 */
export const syncGroup = (organization_id, data) => {
  return client.put(`/publishers/${organization_id}/groups/v2/sync`, data);
};

export default {
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  syncGroup,
};
