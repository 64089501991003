import api from '@/services';
import ServerError from '@/classes/ServerError.js';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
// import moment from 'moment';
import { getGroups, getPlatforms } from '@/utils/store.js';
import { PLATFORMS_ID } from '@/types/platforms';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    review: {
      organization_id: null,
      zendesk_id: null,
      review_id: null,
      unique_id_from_publisher: null,
      review_status: null,
      name: null,
      rating: null,
      review_title: null,
      review_text: null,
      review_date: null,
      review_url: null,
      review_has_text: null,
      review_has_comment: null,
      keep: false,
      created_at: null,
      is_delta: null,
      deleted: null,
      deleted_at: null,
      publisher_id: null,
      publisher_id2: null,
      publisher: null,
      publisher_alias: null,
      active: null,
      request_status: null,
    },
    reviews: [],
    total: 0,
    /**
     * @note publisher refers to monitor sometime in the code
     * @todo TODO: rename to monitors and update all references
     */
    publishers: [],
    selectedPublishers: [],
    // FIXME: temp fix for publisher groups filter
    // or if we migrate to vue 3 we can remove this
    // as vue 3 support root level await
    publishersClone: [],
    publishersList: [],
    publisherGroups: [],
    publishersTotal: 0,
    groupTags: {},
    publishersFilterTotal: 0,
    platforms: [],
    // custom links
    customLinks: [],
    customTotal: 0,
    tempCustomLink: null,
    tempCustomTotal: 0,
    // loading states
    loading: {
      reviews: false,
      groups: false,
      publishers: false,
    },
    query: {
      reviews: {},
      monitors: {},
    },
    lastGmbRefresh: null,
  };
};

export const state = getDefaultState();

export const getters = {
  getPublisherRegex: state => (publisher) => {
    return find(state.publishersList, ['name', publisher]);
  },
  getPublisherGroups: (state) => {
    return state.publisherGroups.filter(group => group.tag_id !== null);
  },
  getGmbMonitors: (state) => {
    return state.publishers.filter((publisher) => {
      return (
        Number.parseInt(publisher.publisher_id) ===
        PLATFORMS_ID.GOOGLE_MY_BUSINESS
      );
    });
  },
  hasGmbMonitors: (state, getters) => {
    return getters.getGmbMonitors.length > 0;
  },
};

export const mutations = {
  SET_REVIEW(state, review) {
    state.review = review;
  },
  SET_REVIEWS(state, reviews) {
    state.reviews = reviews;
  },
  SET_REVIEWS_TOTAL(state, total) {
    state.total = total;
  },
  SET_PUBLISHERS(state, publishers) {
    state.publishers = publishers;
  },
  SET_SELECTED_PUBLISHERS(state, selectedPublishers) {
    state.selectedPublishers = selectedPublishers;
  },
  SET_PUBLISHERS_CLONE(state, publishers) {
    state.publishersClone = publishers;
  },
  SET_PUBLISHERS_LIST(state, publishers) {
    state.publishersList = publishers;
  },
  SET_PUBLISHERS_TOTAL(state, total) {
    state.publishersTotal = total;
  },
  SET_PUBLISHERS_FILTER_TOTAL(state, total) {
    state.publishersFilterTotal = total;
  },
  SET_GROUP_TAGS(state, tags) {
    state.groupTags = tags;
  },
  SET_CUSTOM_LINKS(state, links) {
    state.customLinks = links;
  },
  SET_CUSTOM_TOTAL(state, total) {
    state.customTotal = total;
  },
  SET_TEMP_CUSTOM_LINK(state, link) {
    state.tempCustomLink = link;
  },
  SET_TEMP_CUSTOM_LINK_TOTAL(state, total) {
    state.tempCustomTotal = total;
  },
  SET_GROUP_PUBLISHERS(state, groups) {
    state.publisherGroups = groups;
  },
  SET_LOADING(state, { type, isLoading }) {
    state.loading[type] = isLoading;
  },
  SET_REVIEWS_QUERY(state, query) {
    state.query.reviews = query;
  },
  SET_MONITOR_QUERY(state, query) {
    state.query.monitors = query;
  },
  RESET_GROUP(state) {
    state.group = {
      tag_id: null,
      name: 'All Groups',
    };
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PLATFORMS(state, platforms) {
    state.platforms = platforms;
  },
};

export const actions = {
  // Used for filtering only to
  // prevent users from ddos'ing
  // when selecting filters
  debounceFetchCustomerReviews: debounce(({ dispatch }, query) => {
    dispatch('fetchCustomerReviews', query);
  }, 700),

  fetchCustomerReview({ commit }, review_id) {
    return api.rmvfy
      .getReview(review_id)
      .then((response) => {
        if (response.data) {
          commit('SET_REVIEW', response.data);
          return response.data;
        } else {
          return null;
        }
      })
      .catch((error) => {
        return error;
      });
  },
  async fetchCustomerReviews({ commit, state }, query) {
    commit('SET_LOADING', { type: 'reviews', isLoading: true });

    // Query
    let filter;
    if (query) {
      filter = query;
      commit('SET_REVIEWS_QUERY', query);
    } else {
      filter = state.query.reviews;
    }

    const params = {
      page: filter.page ? filter.page : 1,
      limit: filter.limit ? filter.limit : 25,
      order: filter.sort ? filter.sort : 'date:desc',
      search: filter.search ? filter.search : '',
      group: filter.group ? filter.group : '',
      url_id: filter.monitor ? filter.monitor : '',
      rating: filter.rating ? filter.rating : '',
      publisher: filter.publisher ? filter.publisher : '',
      available: filter.available ? filter.available : '',
      refresh: filter.refresh ? filter.refresh : '',
      start_date: filter.start_date ? filter.start_date : '',
      end_date: filter.end_date ? filter.end_date : '',
      review_has_text: filter.review_has_text ? filter.review_has_text : '',
    };

    try {
      const response = await api.rmvfy.getCustomerReviews(params);
      if (response.data && response.data.data) {
        commit('SET_REVIEWS', response.data.data);
        commit('SET_REVIEWS_TOTAL', response.data.total);
        commit('SET_LOADING', {
          type: 'reviews',
          isLoading: false,
        });
        return response;
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', { type: 'reviews', isLoading: false });
    }
  },

  async fetchCustomLinks({ commit }, query) {
    // TODO: see if a loading state is needed
    // commit('SET_LOADING', { type: 'custom', isLoading: true });

    try {
      const response = await api.rmvfy.getCustomLinkReviewMonitors(
        query.organization_id,
        query.campaign_code,
      );
      if (response.data && response.data.data) {
        commit('SET_CUSTOM_LINKS', response.data.data);
        commit('SET_CUSTOM_TOTAL', response.data.total);
        return response;
      } else if (response.status === 204) {
        // if no data is returned, set to empty array
        commit('SET_CUSTOM_LINKS', []);
        commit('SET_CUSTOM_TOTAL', 0);
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      return error.response;
    } // TODO: if loading state is needed do a finally here to set loading to false
  },

  async fetchCustomerPublishers({ commit, state, dispatch, getters }, query) {
    let filter;
    const params = {
      limit: 999,
      search: '',
      group: '',
    };
    if (query && query.showLoading) {
      commit('SET_LOADING', { type: 'publishers', isLoading: true });
    }
    if (!query?.refresh) {
      if (query) {
        commit('SET_MONITOR_QUERY', query);
        filter = query;
      } else {
        filter = state.query.monitors;
      }
      params.search = filter.monitorSearch ? filter.monitorSearch : '';
      params.group = filter.group ? filter.group : '';
    }

    try {
      const response = await api.rmvfy.getReviewMonitors(params);
      if (response.data.data.monitors) {
        commit('SET_PUBLISHERS', response.data.data.monitors);
        commit('SET_PUBLISHERS_CLONE', response.data.data.monitors);
        commit('SET_PUBLISHERS_LIST', response.data.data.publishers);
        commit('SET_PUBLISHERS_TOTAL', response.data.total);
        commit('SET_PUBLISHERS_FILTER_TOTAL', response.data.total_filter);
        commit('SET_PLATFORMS', getPlatforms(response.data.data.monitors));

        if (query.showLoading) {
          commit('SET_LOADING', {
            type: 'publishers',
            isLoading: false,
          });
        }

        // Since Dec 2023, we also attempt to refresh GMB monitors if applicable.
        // Rules
        // - Not too many monitors (some might have hundreds)
        // - We throttle this request to 1 per 5 minutes (see dispatch)
        const gmbMonitors = getters.getGmbMonitors;
        if (gmbMonitors.length > 0 && gmbMonitors.length <= 10) {
          dispatch('refreshGmbMonitors');
        }
        return response;
      } else throw response;
    } catch (error) {
      return error.response;
    } finally {
      if (query && query.showLoading) {
        commit('SET_LOADING', {
          type: 'publishers',
          isLoading: false,
        });
      }
    }
  },

  fetchCustomerGroups({ commit, rootGetters }, query) {
    if (query?.showLoading) {
      commit('SET_LOADING', { type: 'groups', isLoading: true });
    }

    const organization_id = rootGetters['user/getOID'];
    return api.rmvfyV2.group
      .getGroups(organization_id)
      .then((response) => {
        if (response.data && response.data.data) {
          commit('SET_GROUP_PUBLISHERS', response.data.data);
          const groups = getGroups(response.data.data);

          commit('SET_GROUP_TAGS', groups);
        }
      })
      .catch(() => {
        throw new ServerError(
          'We can not perform this action at this time. Try again later.',
        );
      })
      .finally(() => {
        if (query?.showLoading) {
          commit('SET_LOADING', { type: 'groups', isLoading: false });
        }
      });
  },
  /**
   *
   * @note to be deprecated and replaced by Chris
   */
  updateCustomerGroup({ commit }, data) {
    commit('SET_LOADING', { type: 'groups', isLoading: true });
    return api.crm.updateCustomerGroup(data).then((response) => {
      if (response.data && response.data.data) {
        commit('SET_GROUP_PUBLISHERS', response.data.data);
        commit('SET_LOADING', { type: 'groups', isLoading: false });
        return response;
      } else commit('SET_LOADING', { type: 'groups', isLoading: false });
      throw new ServerError(
        'We can not perform this action at this time. Try again later.',
      );
    });
  },
  /**
   * CHRIS PLAY
   * @note to be deprecated and replaced by Chris
   */
  async updateCustomerGroupV2({ commit }, data) {
    commit('SET_LOADING', { type: 'groups', isLoading: true });

    try {
      const response = await api.rmvfy.updateReviewMonitorGroups({
        data,
      });
      if (response.data && response.data.data) {
        // commented out as we are not using this after update
        // commit('SET_GROUP_PUBLISHERS', response.data.data);
        commit('SET_LOADING', { type: 'groups', isLoading: false });
        return response;
      } else throw response;
    } catch (error) {
      commit('SET_LOADING', { type: 'groups', isLoading: false });
    }
  },
  /**
   *
   * @note to be deprecated and replaced by Chris
   */
  updatePublishers({ commit }, data) {
    commit('SET_LOADING', { type: 'publishers', isLoading: true });
    return api.crm
      .updatePublisher(data)
      .then(({ data }) => {
        if (data && data.data) {
          commit('SET_PUBLISHERS', data.data);
          commit('SET_LOADING', {
            type: 'publishers',
            isLoading: false,
          });
          return data;
        } else
          throw new ServerError(
            'We can not perform this action at this time. Try again later.',
          );
      })
      .catch((error) => {
        commit('SET_LOADING', { type: 'publishers', isLoading: false });
        if (error.name === 'ServerError') {
          return Promise.reject(error.message);
        } else {
          return Promise.reject(error.response.data.message);
        }
      });
  },
  /**
   * Set temp custom link while creating the amplify campaign
   * @param {object} data
   */
  saveTempCustomLink({ commit }, data) {
    commit('SET_TEMP_CUSTOM_LINK', data);
    commit('SET_TEMP_CUSTOM_LINK_TOTAL', 1);
  },

  /**
   * clear up after creating the amplify campaign
   */
  clearTempCustomLink({ state }) {
    state.tempCustomLink = null;
    state.tempCustomTotal = 0;
  },

  /**
   *
   * @param {object} param0
   * @property {object} param0.monitor the monitor object
   * @property {boolean} param0.selected the selected state
   */
  selectMonitor({ state, commit }, { monitor, selected }) {
    // if is selected, add to selectedPublishers
    // check if monitor is already in selectedPublishers first to make sure we don't add duplicates
    let _selectedPublishers = JSON.parse(
      JSON.stringify(state.selectedPublishers),
    );
    if (selected) {
      if (!state.selectedPublishers.find(m => m.id === monitor.id)) {
        _selectedPublishers.push(monitor);
      }
    } else {
      _selectedPublishers = _selectedPublishers.filter(
        m => m.id !== monitor.id,
      );
    }
    commit('SET_SELECTED_PUBLISHERS', _selectedPublishers);
  },

  /**
   * This is our attempt to refetching the latest reviews (and replies) from GMB
   * through the api, however note that this 'triggers' a refetch, but does not
   * immediately return all the latest reviews and replies.
   * In theory this doesn't take long, and next 'reload' will show latest info.
   * @param {object} param
   */
  refreshGmbMonitors({ state }) {
    if (this.lastGmbRefresh) {
      const now = new Date();
      const diff = now - this.lastGmbRefresh;
      // if last refresh was less than 5 minute ago, don't refresh
      if (diff < 60000 * 5) return;
    }

    // Set last refresh to now
    this.lastGmbRefresh = new Date();

    const gmbMonitors = state.publishers.filter((publisher) => {
      return (
        Number.parseInt(publisher.publisher_id) ===
        PLATFORMS_ID.GOOGLE_MY_BUSINESS
      );
    });

    // if there are no gmb monitors, return
    if (!gmbMonitors.length) return;

    // call the api, we don't really expect any response
    // we don't pass in monitor IDs because we have a server-side
    // global refresh by organization id
    api.rmvfyV3.review.refreshGmbMonitors();
  },
};
