import axios from 'axios';
import store from '../../../store';
import router from '../../../router';
import NProgress from 'nprogress';
import axiosRetry from 'axios-retry';
import { progressBarExcludePath } from '@/types/axios';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 0 });

client.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/getToken'];
    if (token) config.headers['X-Removify-Token'] = token;

    // Demo
    const isDemoMode = store.getters['user/isDemoMode'];
    const demoName = localStorage.getItem('_demoname');

    if (isDemoMode && demoName) {
      config.headers['X-Demo-Name'] = demoName;
    }

    // if the config.path matches any of the regexes in progressBarExcludePath
    // then we don't want to show the progress bar
    if (
      !progressBarExcludePath.some((regex) => {
        return regex.test(config.url);
      })
    ) {
      NProgress.start();
    }
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    // Unauthorised User
    if (error.response?.status === 401) {
      // Check if the page is public.
      if (
        router &&
        router.history &&
        router.history.current &&
        router.history.current.meta &&
        !router.history.current.meta.guest
      ) {
        window.location = '/logout?hr=true';
      }
    }

    // Everything else (e.g. 500)
    // Force progressbar done.
    NProgress.done();
    return Promise.reject(error);
  },
);

export default client;
