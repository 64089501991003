<template>
  <label class="wrapper flex items-center">
    <slot>
      {{ label }}
    </slot>
    <input
      type="radio"
      name="radio-input"
      :value="value"
      :disabled="disabled"
      :checked="isChecked"
      @change="updateRadio"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'BaseRadioV2',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      default: '',
      description:
        'The model value, use as v-modal="", do NOT direct give this prop a value',
    },
    value: {
      description:
        'Value of the checkbox, when checked it will be added to the modelValue array',
    },
    label: {
      type: String,
      default: ' ',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    updateRadio(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.875rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: 0.0625rem solid $gray-400;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    background-color: white;
  }

  input:checked ~ .checkmark {
    background-color: $blue;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0.15rem;
    height: 0.15rem;
    border: solid $white;
    border-radius: 50%;
  }
}
</style>
