import client from '@/services/api/RemovifyServiceV2/config.js';

/**
 * Ping
 */
export const ping = () => {
  return client.get('/ping');
};

export default {
  ping,
};
