import api from '@/services';
export const namespaced = true;

export const getDefaultState = () => {
  return {
    invoices: [],
    invoice: {},
    meta: {},
    total: 0,
    error: '',
    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {
  getById: state => (id) => {
    return state.invoices.find(invoice => invoice.invoice_number === id);
  },
  getOutstanding: (state) => {
    return state.meta && Number.parseFloat(state.meta.outstanding) > 0
      ? state.meta.outstanding
      : 0;
  },
  canActionInvoice: () => {
    return true;
    // return rootState.customer.branch !== 'us';
  },
};

export const mutations = {
  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },
  SET_INVOICE(state, invoice) {
    state.invoice = invoice;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  SET_ERROR(state, message) {
    state.error = message;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchInvoices({ commit }, cache = false) {
    commit('SET_LOADING', true);
    try {
      const response = await api.crm.getCustomerInvoice(cache);
      if (response.status === 200) {
        commit('SET_INVOICES', response.data.data);
        commit('SET_TOTAL', response.data.data.length);
        commit('SET_META', response.data.meta);
        return response;
      } else throw response;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        commit('SET_ERROR', error.response.data.message);
      }
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
