import api from '@/services';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    paymentMethods: [],
    branch: 'au',
    defaultMethod: null,
    services: [],
    currencyCode: 'AUD',
    referral: {
      code: '',
      url: '',
    },
    notes: '',
    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {
  hasPaymentMethods: (state) => {
    return Object.keys(state.paymentMethods).length > 0;
  },
  enabledPaymentMethodOptions: (state) => {
    const paymentOptions = ['card', 'auBankAccount', 'other'];
    if (state.branch !== 'au') {
      // Filter out auBankAccount
      paymentOptions.splice(1, 1);
    }
    return paymentOptions;
  },
};

export const mutations = {
  SET_PAYMENT_METHODS(state, list) {
    state.paymentMethods = list;
  },
  SET_DEFAULT_METHODS(state, method) {
    state.defaultMethod = method;
  },
  SET_BRANCH(state, branch) {
    state.branch = branch;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  SET_SERVICES(state, list) {
    state.services = list;
  },
  SET_REFERRAL(state, referral) {
    state.referral.code = referral.code;
    state.referral.url = referral.url;
  },
  SET_NOTE(state, note) {
    state.notes = note;
  },
  SET_CURRENCY_CODE(state, code) {
    state.currencyCode = code;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchCustomer({ commit }, cache = false) {
    commit('SET_LOADING', true);
    try {
      const response = await api.crm.getCustomer(cache);
      if (response.status === 200) {
        // Customer's services
        if (response.data.data && response.data.data.services) {
          commit('SET_SERVICES', response.data.data.services);
        }

        // Customer's payment methods
        if (response.data.data && response.data.data.payment_methods) {
          commit('SET_PAYMENT_METHODS', response.data.data.payment_methods);
          commit('SET_DEFAULT_METHODS', response.data.data.payment_method);
        }

        // Customer's billing notes
        if (response.data.data && response.data.data.billing_notes) {
          commit('SET_NOTE', response.data.data.billing_notes);
        }

        // Customer's billing notes
        if (response.data.data && response.data.data.branch) {
          commit('SET_BRANCH', response.data.data.branch);
        }

        // Customer's referral code
        if (
          response.data.data &&
          response.data.data.referrer_code &&
          response.data.data.referrer_url
        ) {
          commit('SET_REFERRAL', {
            code: response.data.data.referrer_code,
            url: response.data.data.referrer_url,
          });
        }

        // Customer's currency code
        if (response.data.data && response.data.data.currency_code) {
          commit('SET_CURRENCY_CODE', response.data.data.currency_code);
        }
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
