import mixpanel from 'mixpanel-browser';
import type { User } from '@/types/user';

const appEnv = import.meta.env.VITE_APP_ENV;
export function initMixpanel() {
  const mixpanelToken = import.meta.env.VITE_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: appEnv !== 'production',
    track_pageview: true,
    persistence: 'localStorage',
  });
  mixpanel.register({
    app_env: appEnv,
  });
}

export function identifyUser(user: User) {
  mixpanel.identify(user.id);

  mixpanel.people.set({
    $email: user.email,
    $name: user.name,
    $isDev: user.isDev,
    $isAdmin: user.isAdmin,
  });
}
