/**
 * @note this is a base params for all the api
 * make more specific params for each api
 */
export interface BaseParams {
  limit: number;
  page: number;
  start_date: string;
  end_date: string;
  order: string;
  search: string;
}

const createPathRegex = (path: string) => new RegExp('^/' + path + '.*');
export const progressBarExcludePath = [createPathRegex('ping')];
