<template>
  <div class="notifications">
    <Notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    >
    </Notification>
  </div>
</template>

<script setup lang="ts">
import { useNotificationStore } from './useNotificationStore';

const { notifications } = useNotificationStore();
const Notification = defineAsyncComponent(() => import('./Notification.vue'));
</script>
