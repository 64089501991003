export interface ToastOptions {
  id?: string;
  message?: string;
  title?: string;
  icon?: string;
  verticalAlign?: 'top' | 'bottom';
  horizontalAlign?: 'left' | 'center' | 'right';
  type?:
    | 'success'
    | 'warning'
    | 'default'
    | 'info'
    | 'primary'
    | 'danger'
    | 'neutral';
  timeout?: number;
  timestamp?: Date | number;
  timer?: NodeJS.Timeout;
  component?: any;
  showClose?: boolean;
  closeOnClick?: boolean;
  clickHandler?: (event: Event) => void;
  clear?: boolean;
  overlap?: boolean;
}
type DefaultToastOptions = Required<
  Pick<
    ToastOptions,
    | 'overlap'
    | 'timeout'
    | 'verticalAlign'
    | 'horizontalAlign'
    | 'type'
    | 'showClose'
    | 'closeOnClick'
  >
>;

export type Notification = DefaultToastOptions &
  ToastOptions & { id: string; timestamp: number | Date; };
export const defaultToastOptions: DefaultToastOptions = {
  overlap: false,
  verticalAlign: 'top',
  horizontalAlign: 'right',
  type: 'info',
  timeout: 5000,
  closeOnClick: true,
  showClose: true,
} as const;
