import type { AxiosError } from 'axios';
import client from '@/services/api/RemovifyServiceV3/config';
import store from '@/store';
import type {
  BaseReporting,
  Reporting,
  ReportingListResponse,
  ReportingTemplateList,
} from '@/types/Reporting/reporting';
import {
  baseReportingSchema,
  reportingListSchema,
  reportingSchema,
  reportingTemplateSchema,
} from '@/types/Reporting/reporting';
import type { Module, ModuleMapping } from '@/types/Reporting/module';
import { moduleMappingSchema } from '@/types/Reporting/module';
import type { BaseParams } from '@/types/axios';
import { useError } from '@/composables/error';
// TODO: refactor zod part use this function
// import { verifyData } from '@/utils/zod';

const { addBreadcrumbForError } = useError();

/**
 * Create a new reporting
 * @param name
 * @returns
 */
export const createReporting = async (name: string, created_by: number) => {
  try {
    const response = await client.post<{
      data: Reporting;
      message: string;
    }>(`/o/${store.getters['user/getOID']}/reporting`, {
      name,
      created_by,
    });

    if (response.data?.data && response.status === 201) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = reportingSchema.parse(response.data.data);
        return {
          error: null,
          data,
        };
      } catch (error) {
        addBreadcrumbForError(error);
        return {
          error,
          data: response.data.data as Reporting,
        };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }

  return { data: null, error: new Error('Unknown error') };
};

/**
 * Get the reporting settings
 */
export const getReporting = async (params: Omit<BaseParams, 'search'>) => {
  try {
    const response = await client.get<ReportingListResponse>(
      `/o/${store.getters['user/getOID']}/reporting`,
      {
        params,
      },
    );

    if (response.data && response.status === 200) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = reportingListSchema.parse(response.data);
        return { error: null, data };
      } catch (error) {
        addBreadcrumbForError(error);
        return { error, data: null };
      }
    }
    if (response.status === 204) {
      return {
        error: null,
        data: { data: [], total: 0 } as ReportingListResponse,
      };
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Get the report by id
 * This include the modules
 * @param id
 * @returns
 */
export const getReportById = async (id: number) => {
  try {
    const response = await client.get<unknown>(
      `/o/${store.getters['user/getOID']}/reporting/${id}`,
    );

    if (response.data) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = reportingSchema.parse(response.data);
        return { error: null, data };
      } catch (error) {
        addBreadcrumbForError(error);
        return { error, data: null };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Delete the report by id
 * @param id
 * @returns
 */
export const deleteReportById = async (id: number) => {
  try {
    const response = await client.delete(
      `/o/${store.getters['user/getOID']}/reporting/${id}`,
    );

    if (response.status === 200) {
      return { error: null };
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Clone the report by id
 * @param id
 * @returns
 */
export const cloneReportById = async (id: number) => {
  try {
    const response = await client.post<{
      data: Reporting;
      message: string;
    }>(`/o/${store.getters['user/getOID']}/reporting/${id}/clone`, {
      created_by: store.getters['user/getUserId'],
    });

    if (response.data?.data && response.status === 201) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = reportingSchema.parse(response.data.data);
        return {
          error: null,
          data,
        };
      } catch (error) {
        addBreadcrumbForError(error);
        return {
          error,
          data: response.data.data as Reporting,
        };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Update the report by id
 * @param id
 * @param param1
 * @returns
 */
export const updateReportById = async (
  id: number,
  { name, created_by }: { name?: string; created_by?: number; },
) => {
  try {
    const response = await client.put<{
      data: BaseReporting;
      message: string;
    }>(`/o/${store.getters['user/getOID']}/reporting/${id}`, {
      name,
      created_by,
    });

    if (response.data?.data && response.status === 200) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = baseReportingSchema.parse(response.data.data);
        return {
          error: null,
          data,
        };
      } catch (error) {
        addBreadcrumbForError(error);
        return {
          error,
          data: response.data.data as BaseReporting,
        };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Get the modules mapping like [{ module_id: 1, slug: 'moduleFiveStar'}]
 * @returns
 */
export const getModulesMapping = async () => {
  try {
    const response = await client.get<{
      data: ModuleMapping;
      message: string;
    }>(`/o/${store.getters['user/getOID']}/reporting/module/map`);

    if (response.data?.data && response.status === 200) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = moduleMappingSchema.parse(response.data.data);
        return {
          error: null,
          data,
        };
      } catch (error) {
        addBreadcrumbForError(error);
        return {
          error,
          data: response.data.data as ModuleMapping,
        };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Sync the reporting
 * @param reporting_id
 * @param modules partial module list {reporting_module_id, setting}
 * @note the weight will be recalculated on the backend, and module id will never change
 * @returns
 */
export const syncReporting = async (
  reporting_id: number,
  modules: Pick<Module, 'reporting_module_id' | 'setting'>[],
) => {
  try {
    const response = await client.post<{ message: string; }>(
      `/o/${store.getters['user/getOID']}/reporting/${reporting_id}/sync`,
      {
        modules,
      },
    );

    if (response.status === 200) {
      return { error: null };
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error: error as AxiosError | Error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

/**
 * Get the modules mapping like [{ module_id: 1, slug: 'moduleFiveStar'}]
 * @returns
 */
export const getReportingTemplate = async () => {
  try {
    const response = await client.get<{
      data: ReportingTemplateList;
      message: string;
    }>(`/o/${store.getters['user/getOID']}/reporting/template`);

    if (response.data?.data && response.status === 200) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = reportingTemplateSchema.parse(response.data.data);
        return {
          error: null,
          data,
        };
      } catch (error) {
        addBreadcrumbForError(error);
        return {
          error,
          data: response.data.data as ReportingTemplateList,
        };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

export default {
  createReporting,
  getReporting,
  getReportById,
  deleteReportById,
  cloneReportById,
  updateReportById,
  getModulesMapping,
  syncReporting,
  getReportingTemplate,
};
