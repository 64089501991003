import { z } from 'zod';

export const moduleSchema = z.object({
  reporting_module_id: z.number(),
  module_slug: z.string(),
  setting: z.any(),
});

export const reportingModuleSchema = moduleSchema.merge(
  z.object({ reporting_id: z.number() }),
);

export const templateModuleSchema = moduleSchema.merge(
  z.object({ template_id: z.number() }),
);

export const moduleMappingSchema = z.array(
  z.object({
    slug: z.string(),
    name: z.string(),
    description: z.string(),
  }),
);

/**
 * @note set the 'setting' to 'any' because the type of 'setting' is an object but returned as a string
 * However while developing we still want the type check to work
 */
export type Module = Omit<z.infer<typeof moduleSchema>, 'setting'> & {
  reporting_id: number;
  setting: typeof defaultModuleSetting;
};

export type ModuleMapping = z.infer<typeof moduleMappingSchema>;

export type id =
  | 'ReviewPerformanceOverview'
  | 'TopPerformingMonitors'
  | 'LowestPerformingMonitors'
  | 'ReviewCountDistribution'
  | 'NewVsTotalReviews'
  | 'AverageRatingPerPublisher'
  | 'NegativeReviewAnalysis'
  | 'FiveStarReviewAnalysis'
  | 'AveragePublisherRating'
  | 'CommonWordsUsedInReviews'
  | 'LocationGroupComparison'
  | 'ReviewPublisherGrowth'
  | 'StarRatingDistribution'
  | 'UpwardsTrendingMonitors'
  | 'DownwardTrendingMonitors'
  | 'RemovalsByStatus'
  | 'RemovalsByPublisher'
  | 'TotalRemovalsOverTime'
  | 'AMPLIFYResponses'
  | 'AMPLIFYTopCampaigns'
  | 'AMPLIFYRatingSummary'
  | 'AMPLIFYAverageRating'
  | 'AMPLIFYMostComplaintsIntercepted'
  | 'AMPLIFYPublishedPublicReviews';

export const defaultModuleSetting = {
  config: {
    width: 6,
  },
};
