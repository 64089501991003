const showSidebar = ref(false);
const sidebarLinks = ref([]);
const isMinimized = ref(false);
const breakpoint = ref(1200);
const hovered = ref(false);
export const useSidebarStore = () => {
  const displaySidebar = (bool: boolean) => {
    if (window.innerWidth > breakpoint.value) {
      return;
    }

    isMinimized.value = !bool;
    showSidebar.value = bool;

    const docClasses = document.body.classList;
    if (bool) {
      docClasses.add('g-sidenav-pinned');
      docClasses.add('g-sidenav-show');
      docClasses.remove('g-sidenav-hidden');
    } else {
      docClasses.add('g-sidenav-hidden');
      docClasses.remove('g-sidenav-pinned');
      docClasses.remove('g-sidenav-show');
    }
  };
  const toggleMinimize = () => {
    isMinimized.value = !isMinimized.value;
    const docClasses = document.body.classList;
    if (isMinimized.value) {
      docClasses.add('g-sidenav-hidden');
      docClasses.remove('g-sidenav-pinned');
      docClasses.remove('g-sidenav-show');
    } else {
      docClasses.add('g-sidenav-pinned');
      docClasses.add('g-sidenav-show');
      docClasses.remove('g-sidenav-hidden');
    }
    if (hovered.value) {
      docClasses.add('g-sidenav-show');
    }
  };

  const onMouseEnter = () => {
    hovered.value = true;
    if (isMinimized.value) {
      document.body.classList.add('g-sidenav-show');
      document.body.classList.remove('g-sidenav-hidden');
    }
  };

  const onMouseLeave = () => {
    hovered.value = false;
    if (isMinimized.value) {
      const docClasses = document.body.classList;
      docClasses.remove('g-sidenav-show');
      docClasses.add('g-sidenav-hide');
      // FIXME: test with nextTick if not working go back to settimeout
      nextTick(() => {
        docClasses.remove('g-sidenav-hide');
        docClasses.add('g-sidenav-hidden');
      });
    }
  };

  return {
    displaySidebar,
    toggleMinimize,
    onMouseEnter,
    onMouseLeave,
    showSidebar,
    sidebarLinks,
    isMinimized,
    breakpoint,
    hovered,
  };
};
