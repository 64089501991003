// globalMixins.js
import moment from 'moment-timezone';

export default {
  methods: {
    formatDate(date) {
      const d = moment(date);
      return d.format('dddd, D MMMM YYYY');
    },
    formatMsgDate(date) {
      const d = moment(date);
      return d.format('D MMM YYYY');
    },
    formatPlainDate(date) {
      const d = moment(date);
      return d.format('D MMMM YYYY');
    },
    formatInvoiceDate(date) {
      const d = moment(date);
      return d.format('D MMMM YYYY');
    },
    formatTime(date) {
      const d = moment(date);
      return d.format('h:mma');
    },
    formatFullDate(date) {
      const d = moment(date);
      return d.format('dddd, MMMM Do, YYYY [at] h:mma');
    },
    /**
     * @param {*} date
     * @param {import('@/types/time').TimeZone} timezone
     */
    twitterDate(date, timezone = 'Australia/Melbourne') {
      return moment.tz(date, timezone).fromNow();
    },
    capitalise(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    firstName(value) {
      if (!value) return '';
      return value.split(' ', 1)[0];
    },
    firstInitial(value) {
      if (!value) return 'U';
      return value.charAt(0).toUpperCase();
    },
    humanFileSize(size) {
      if (size < 1024) return size + ' B';
      const i = Math.floor(Math.log(size) / Math.log(1024));
      let num = size / 1024 ** i;
      const round = Math.round(num);
      num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
      return `${num} ${'KMGTPEZY'[i - 1]}B`;
    },
    trim(text) {
      const maxLength = 150;
      return text && text.length > maxLength
        ? text.slice(0, maxLength).split(' ').slice(0, -1).join(' ')
        : text;
    },
    formatRating(number) {
      if (!number) return '0.0';
      return Number.parseFloat(number).toFixed(1);
    },
    formatNumber(val) {
      if (!val) return 0;
      return new Intl.NumberFormat('en-AU').format(val);
    },
  },
};
