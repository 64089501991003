import client from '@/services/api/RemovifyServiceV3/config';
import store from '@/store';
import { useError } from '@/composables/error';

const { addBreadcrumbForError } = useError();

/**
 * Trigger refresh to GMB monitors
 * @returns
 */
export const refreshGmbMonitors = async () => {
  try {
    await client.get<{
      data: object;
      message: string;
    }>(`/gmb/run/${store.getters['user/getOID']}`, {
      baseURL: `${import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL}/v0`,
    });
  } catch (error) {
    addBreadcrumbForError(error);
    return { data: null };
  }

  return { data: null };
};

export default {
  refreshGmbMonitors,
};
