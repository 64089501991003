/**
 * @description Used for token validation and error handling
 */
export default class AuthError extends Error {
  constructor(message, props) {
    super(message);
    this.name = 'AuthError';

    if (props && typeof props === 'object') {
      Object.assign(this, props);
    }
  }
}
