import BaseInput from '@/components/Argon/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/Argon/BaseDropdown.vue';
import Card from '@/components/Argon/Cards/Card.vue';
import Modal from '@/components/Argon/Modal.vue';
import BaseButton from '@/components/Argon/BaseButton.vue';
import Badge from '@/components/Argon/Badge.vue';
import BaseCheckbox from '@/components/Argon/Inputs/BaseCheckbox.vue';
import BaseCheckboxV2 from '@/components/Argon/Inputs/BaseCheckboxV2.vue';
import BaseRadio from '@/components/Argon/Inputs/BaseRadio.vue';
import BaseRadioV2 from '@/components/Argon/Inputs/BaseRadioV2.vue';
import BaseProgress from '@/components/Argon/BaseProgress.vue';
import BasePagination from '@/components/Argon/BasePagination.vue';
import BaseAlert from '@/components/Argon/BaseAlert.vue';
import BaseNav from '@/components/Argon/Navbar/BaseNav.vue';
import BaseHeader from '@/components/Argon/BaseHeader.vue';
import BaseMoney from '@/components/Removify/BaseMoney.vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// import StatsCard from "@/components/Argon/Cards/StatsCard.vue";
// import BaseSwitch from "@/components/Argon/BaseSwitch.vue";
// import RouteBreadcrumb from "@/components/Argon/Breadcrumb/RouteBreadcrumb.vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
const GlobalComponents = {
  install(app) {
    // Argon Components
    app.component(Badge.name, Badge);
    app.component(BaseAlert.name, BaseAlert);
    app.component(BaseButton.name, BaseButton);
    app.component(BaseCheckbox.name, BaseCheckbox);
    app.component(BaseCheckboxV2.name, BaseCheckboxV2);
    app.component(BaseHeader.name, BaseHeader);
    app.component(BaseInput.name, BaseInput);
    app.component(BaseDropdown.name, BaseDropdown);
    app.component(BaseNav.name, BaseNav);
    app.component(BasePagination.name, BasePagination);
    app.component(BaseProgress.name, BaseProgress);
    app.component(BaseRadio.name, BaseRadio);
    app.component(BaseRadioV2.name, BaseRadioV2);
    app.component(BaseMoney.name, BaseMoney);
    app.component(Card.name, Card);
    app.component(Modal.name, Modal);
    app.component('ValidationObserver', Form);
    app.component('ValidationProvider', Field);
    app.component('ValidationError', ErrorMessage);
    app.use(ElementPlus);
    // Vue.component(BaseSwitch.name, BaseSwitch);
    // Vue.component(StatsCard.name, StatsCard);
    // Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
  },
};

export default GlobalComponents;
