import client from '@/services/api/RemovifyServiceV2/config.js';

/**
 * Send a message to a contact immediately
 * @param {number} organization_id
 * @param {string} campaign_code
 * @param {string} contact_code
 * @returns {Promise}
 */
export const sendMessage = (organization_id, campaign_code, contact_code) => {
  return client.post(
    `/amplify/${organization_id}/campaign/${campaign_code}/contact/${contact_code}/send`,
  );
};

export default {
  sendMessage,
};
