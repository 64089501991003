<template>
  <div
    v-show="show"
    class="modal fade"
    :class="[
      { 'show block': show },
      { hidden: !show },
      { 'modal-mini': type === 'mini' },
      { 'modal-headless': headless },
    ]"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
    @mousedown.self="closeModalOutside"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="[
        {
          'modal-notice': type === 'notice',
          [`modal-${size}`]: size,
        },
        modalClasses,
      ]"
    >
      <div
        class="modal-content"
        :class="[
          gradient ? `bg-gradient-${gradient}` : '',
          modalContentClasses,
          { show },
        ]"
      >
        <div
          v-if="$slots.header"
          class="modal-header bg-legacy-default"
          :class="[headerClasses]"
        >
          <slot name="header"></slot>
          <slot name="close-button">
            <button
              v-if="showClose"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <span :aria-hidden="!show">
                <i-rmv-interface-close style="color: white" />
              </span>
            </button>
          </slot>
        </div>

        <div class="modal-body" :class="bodyClasses">
          <slot></slot>
        </div>

        <div v-if="$slots.footer" class="modal-footer" :class="footerClasses">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';

export default {
  name: 'Modal',
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    clickOutside: {
      type: Boolean,
      default: true,
    },
    outsideCloseMessage: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.includes(value);
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    size: {
      type: String,
      description: 'Modal size',
      validator(value) {
        const acceptedValues = ['', 'sm', 'lg', 'xl'];
        return acceptedValues.includes(value);
      },
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes',
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)',
    },
    headless: {
      type: Boolean,
      description: 'Removes modal header',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 200,
      description: 'Modal transition duration',
    },
  },
  emits: ['update:show', 'close'],
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    closeModalOutside() {
      if (this.clickOutside) this.closeModal();
      else if (this.outsideCloseMessage !== '') {
        swal
          .fire({
            title: 'Warning',
            text: this.outsideCloseMessage,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Discard & Exit',
            cancelButtonText: 'Cancel',
          })
          .then((result) => {
            if (result.value) {
              this.closeModal();
            }
          });
      }
    },
  },
};
</script>

<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-content.show {
  animation: modalFadeInUp 0.5s ease forwards;
}

@keyframes modalFadeInUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
