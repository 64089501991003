<template>
  <side-bar id="global-nav-bar" :auto-close="true" :padding="!isMobile()">
    <AccountDropdown
      v-if="
        isMobile() &&
          (account.isDev || account.isAdmin || account.isMultiAccount)
      "
    />
    <template #links>
      <!-- Primary -->
      <sidebar-item
        v-for="m in menu"
        :key="m.id"
        :link="{
          name: m.meta.title,
          path: m.path,
          icon: m.meta.icon,
        }"
        :badge="m.meta.badge"
        :children-length="m.children?.length ?? 0"
        :menu="true"
      >
        <sidebar-item
          v-for="sub in m.children"
          :key="sub.id"
          :link="{
            name: sub.meta.title,
            path: sub.path,
            icon: sub.meta.icon,
          }"
          :badge="sub.meta.badge"
          @click="linkClick"
        />
      </sidebar-item>
    </template>

    <template #links-after></template>

    <template #bottomLinks>
      <!-- Primary Bottom -->
      <sidebar-item
        v-for="m in menuBottom"
        :key="m.id"
        :link="{
          name: m.meta.title,
          path: m.path,
          icon: m.meta.icon,
        }"
        :children-length="m.children?.length ?? 0"
        :menu="true"
      >
        <sidebar-item
          v-for="sub in m.children"
          :key="sub.id"
          :link="{
            name: sub.meta.title,
            path: sub.path,
            icon: sub.meta.icon,
          }"
          @click="linkClick"
        />
      </sidebar-item>
    </template>
  </side-bar>
</template>

<script>
import { isMobile } from '@/mixins/mobile.js';
import camelCase from 'lodash/camelCase';
import { tracking } from '@/utils';

export default {
  mixins: [isMobile],
  components: {
    AccountDropdown: defineAsyncComponent(
      () => import('@/views/User/SubAccount/Sidebar/DropdownV2.vue'),
    ),
  },
  // data() {
  //     return {
  //         menu: [],
  //     };
  // },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
    menu() {
      return this.getMenu(this.routes, 'primary');
    },
    menuBottom() {
      return this.getMenu(this.routes, 'primary-bottom');
    },
    isExpanded() {
      return this.$store.state.toggleMenu;
    },
    account() {
      return {
        selected: this.$store.state.user.selectedOrganization,
        isDev: this.$store.getters['user/isDev'],
        isAdmin: this.$store.getters['user/isAdmin'],
        isMultiAccount: this.$store.getters['user/isMultiAccount'],
      };
    },
  },
  methods: {
    camelCase(v) {
      return camelCase(v);
    },
    linkClick(link) {
      // tracking custom event menu item clicks
      tracking('gtm', {
        event: 'click_custom',
        customLabel: link.name,
        customType: 'menu_item',
      });
    },
    getMenu(routes, type) {
      // Got to separate the $routes object
      // from being used directly in case of
      // an accidental mutation
      const arr = routes;
      const newArray = [];
      arr.forEach((route) => {
        if (route.meta && route.meta.menu && route.meta.scopes) {
          let childrenArr = [];
          if (route.children && route.children.length > 0) {
            childrenArr = this.getMenu(route.children, type);
          }

          if (
            route.meta.menu === type &&
            this.$store.getters['user/checkUserScope'](route.meta.scopes)
          ) {
            newArray.push({
              meta: route.meta,
              name: route.name,
              path: route.path,
              children: childrenArr,
            });
          }
        }
      });
      return newArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.reseller {
  :deep(.nav-link) {
    &.active {
      background-color: transparent !important;
    }
  }
}
</style>
