export const Removals = [
  {
    path: '/removals',
    name: 'ticket-list',
    component: () => import('@/views/Removals/Index.vue'),
    props: true,
    meta: {
      title: 'Removals',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['tickets.read'],
      menu: 'primary',
      icon: 'ri ri-brand',
    },
    children: [
      {
        path: '/removals/:id',
        name: 'ticket',
        props: true,
        meta: {
          title: 'Removals',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['tickets.read'],
          icon: 'ri ri-brand',
        },
      },
    ],
  },
];
