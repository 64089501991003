export const Email = [
  {
    path: '/t/:user_id/:customer_id/:signature',
    name: 'email-tracking',
    component: () => import('@/views/Email/Index.vue'),
    meta: {
      title: '...',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
  {
    path: '/t/:user_id/:signature',
    name: 'email-tracking-old',
    component: () => import('@/views/Email/Index.vue'),
    meta: {
      title: '...',
      layout: 'Blank',
      guest: true,
      scopes: [],
    },
  },
];
