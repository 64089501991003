import client from '@/services/api/RemovifyServiceV3/config';
import store from '@/store';
import type { Module } from '@/types/Reporting/module';
import {
  defaultModuleSetting,
  reportingModuleSchema,
} from '@/types/Reporting/module';
import type { ZodError } from 'zod';
import type { AxiosError } from 'axios';
import { useError } from '@/composables/error';

const { addBreadcrumbForError } = useError();

export const createModule = async (
  reporting_id: number,
  module_slug: string,
  created_by: number,
) => {
  try {
    const response = await client.post<{
      data: Module;
      message: string;
    }>(`/o/${store.getters['user/getOID']}/reporting/${reporting_id}/module`, {
      module_type: module_slug,
      setting: defaultModuleSetting,
      created_by,
    });
    if (response.data?.data && response.status === 201) {
      /**
       * Use zod to validate the response data
       * @note use a nested try catch because zod is only for type checking
       * Thus, the operation has done anyway. So need to pass the data with error
       * We should update the UI but notify the user maybe their something wrong with the data
       */
      try {
        const data = reportingModuleSchema.parse(response.data.data);
        return {
          error: null,
          data,
        };
      } catch (error) {
        addBreadcrumbForError(error);
        return {
          error: error as ZodError,
          data: response.data.data as Module,
        };
      }
    }
  } catch (error) {
    addBreadcrumbForError(error);
    return { error: error as AxiosError | Error, data: null };
  }
  return { data: null, error: new Error('Unknown error') };
};

export default {
  createModule,
};
