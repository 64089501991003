/**
 * @description Used for checking scopes in routes
 */
export default class ScopeError extends Error {
  constructor(message, props) {
    super(message);
    this.name = 'ScopeError';

    if (props && typeof props === 'object') {
      Object.assign(this, props);
    }
  }
}
