<template>
  <!-- Outstanding balance -->
  <div v-if="hasOutstandingBalance" class="global-alerts container-fluid overdue-alert mt-6">
    <base-alert type="warning" class="mb-0">
      <div
        v-if="!isMobile()"
        class="flex items-center justify-between"
      >
        <div class="mr-2 flex">
          <div data-notify="icon" class="alert-icon">
            <i class="ri ri-alert"></i>
          </div>
          <div class="text-[0.8125rem]">
            You currently have <strong>overdue invoices</strong> on your
            account.<br />
            Please provide immediate payment to ensure that service can continue
            without disruption, and avoid the possibility of past or pending
            removals being reinstated by the publisher.
          </div>
        </div>
        <base-button
          type="white"
          outline
          size="sm"
          class="btn-min-width"
          label="Go to Billing"
          @click="gotoBilling()"
        />
      </div>
      <div v-else class="text-[0.8125rem]">
        You currently have <strong>overdue invoices</strong> on your account.
        <span
          class="text-link font-bold text-white underline"
          @click="gotoBilling()"
        >Go to billing</span>
      </div>
    </base-alert>
  </div>
</template>

<script>
/**
 * This is the place for global announcement
 */
import { isMobile } from '@/mixins/mobile.js';
import { tracking } from '@/utils';
import OverdueBalance from '@/views/Invoice/Overdue/Modal.vue';

export default {
  mixins: [isMobile],
  computed: {
    hasOutstandingBalance() {
      return (
        this.$store.getters['user/hasOutstandingBalance'] &&
        this.$route.name !== 'messages' &&
        this.$route.name !== 'invoices'
      );
    },
  },
  created() {
    // check for outstanding balance
    // setTimeout(() => {
    //     this.checkOutstandingBalance();
    // }, 2000);
  },
  methods: {
    checkOutstandingBalance() {
      if (this.hasOutstandingBalance) {
        this.$store.dispatch('modal/setModal', {
          title: 'Overdue Balance',
          visibility: true,
          component: OverdueBalance,
          showProgressBar: false,
          clickOutside: false,
        });
      }
    },
    gotoBilling() {
      tracking('hj', {
        name: 'tagRecording',
        value: ['Visit Overdue Payment'],
      });
      tracking('gtm', {
        event: 'visitOverduePayment',
      });

      this.$router.push({ name: 'invoices' }, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.alert-text) {
  display: block;
}

/* Conflict */
.notifications .alert {
  position: initial;
}

@media print {
  .global-alerts {
    display: none;
  }
}

.overdue-alert {
  :deep(.alert-text) {
    max-width: 100%;
  }
}
</style>
