import axios from 'axios';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  // withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export default {
  /**
   * Fetch GMB connect info
   * @param {object} data
   */
  gmbConnectFetchInfo({ org_id }) {
    return client.post('/gmb-connect/info', {
      org_id,
    });
  },
  /**
   * Exchange authorization code into access and refresh token
   * @param {object} data
   */
  gmbConnectTokenize({ code, org_id }) {
    return client.post('/gmb-connect/tokenize', {
      org_id,
      code,
    });
  },
};
