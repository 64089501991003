export const Reviews = [
  {
    path: '/reviews',
    redirect: { name: 'review-monitor' },
    component: () => import('@/views/Amplify/ParentIndex.vue'), // A wrapper component to render child components
    props: true,
    meta: {
      title: 'Reviews HQ',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['reviews.read'],
      menu: 'primary',
      icon: 'ri ri-location',
    },
    children: [
      {
        path: '/reviews/monitors',
        name: 'review-monitor',
        component: () => import('@/views/Reviews/Monitors/Index.vue'),
        props: true,
        meta: {
          title: 'Monitoring',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['reviews.read'],
          menu: 'primary',
        },
      },
      {
        path: '/reviews/reports',
        name: 'review-reports',
        component: () => import('@/views/Reviews/Reports/Index.vue'),
        props: true,
        meta: {
          title: 'Reports',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: ['report.read', 'reviews.read'],
          menu: 'primary',
        },
      },
      {
        path: '/reviews/reports/:url_id',
        name: 'review-insights',
        component: () => import('@/views/Reviews/Insights/Index.vue'),
        props: true,
        meta: {
          title: 'Insights',
          layout: 'Dashboard',
          requiresAuth: true,
          scopes: [],
        },
      },
    ],
  },
  {
    path: '/reviews/monitors/manage',
    name: 'review-manager',
    component: () => import('@/views/Reviews/Manage/Index.vue'),
    // component: () => import('@/views/Reviews/Manage/IndexOld.vue'),
    props: true,
    meta: {
      title: 'Review Manager',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['reviews.read'],
    },
  },
  {
    path: '/reviews/monitors/manage/bulk',
    name: 'review-manager-v2',
    component: () => import('@/views/Reviews/Manage/Bulk/Index.vue'),
    props: true,
    meta: {
      title: 'Review Manager',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['reviews.read', 'dev'],
    },
  },
];
