import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import * as rules from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

configure({
  generateMessage: localize({
    en: {
      fields: {
        postcode: {
          required: 'Please enter valid ZIP/Postal Code',
        },
      },
      ...en,
    },
  }),
});

Object.keys(rules).forEach((rule: string) => {
  // @ts-expect-error The @vee-validate/rules package does not have types but it works
  defineRule(rule, rules[rule]);
});
