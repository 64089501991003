<template>
  <div
    class="custom-control custom-checkbox"
    :class="[{ disabled }, { [`custom-checkbox-${type}`]: type }, inlineClass]"
  >
    <input
      v-if="checkedExists"
      :id="cbId"
      v-model="model"
      class="custom-control-input"
      :class="inputClasses"
      type="checkbox"
      :disabled="disabled"
    />

    <input
      v-else
      :id="cbId"
      class="custom-control-input"
      :class="inputClasses"
      type="checkbox"
      :disabled="disabled"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />

    <label :for="cbId" class="custom-control-label">
      <slot>
        <span v-if="inline" class="inline-block">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    modelValue: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked',
    },
    /**
     * @deprecated Use `modelValue` instead
     * And only use `checked` for read-only purposes if there's a strong reason to do so
     */
    checked: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked',
      default: undefined,
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled',
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline',
    },
    inputClasses: {
      type: [String, Object, Array],
      description: 'Checkbox input classes',
    },
    type: {
      type: String,
      description: 'Checkbox type (e.g info, danger etc)',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      cbId: '',
      touched: false,
    };
  },
  computed: {
    checkedExists() {
      return typeof this.checked !== 'undefined';
    },
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('update:modelValue', check);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return '';
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>
