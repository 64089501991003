<template>
  <div class="wrapper" :class="{ 'mobile-app': isMobile() }">
    <notifications />
    <DashboardSidebar />
    <div class="main-content">
      <base-alert
        v-if="globalAlert.show"
        class="global-alerts sticky top-0 z-50 m-0 flex justify-center"
        type="warning"
      >
        <div class="flex items-center justify-center">
          <i class="ri ri-alert ri-15x mr-2"></i>
          <p class="alert-message m-0">
            {{ globalAlert.message }}
          </p>
          <base-button
            id="dismiss-global-alert"
            class="ml-6"
            outline
            type="neutral"
            size="sm"
            @click="dismissGlobalAlert"
          >
            Dismiss
          </base-button>
        </div>
      </base-alert>
      <DashboardNavbar :type="$route.meta.navbarType"></DashboardNavbar>
      <DashboardGlobalAlert></DashboardGlobalAlert>
      <router-view
        class="dashboard-content"
        @click="$sidebar.displaySidebar(false)"
      />
      <!-- </fade-transition> -->
      <DashboardFooter />
    </div>
    <GlobalModal />
    <CartSidebar />
  </div>
</template>

<script>
import { useLocalStorage } from '@vueuse/core';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import DashboardSidebar from './Dashboard/Sidebar.vue';
import DashboardNavbar from './Dashboard/Navbar.vue';
import DashboardFooter from './Dashboard/Footer.vue';
import DashboardGlobalAlert from './Dashboard/GlobalAlert.vue';
import GlobalModal from './Dashboard/Modal.vue';
// import { FadeTransition } from 'vue2-transitions';
import { isMobile } from '@/mixins/mobile.js';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    const _ = new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

// import Announcement from '../components/Removify/Announcement.vue';

export default {
  mixins: [isMobile],
  components: {
    DashboardNavbar,
    DashboardSidebar,
    DashboardGlobalAlert,
    GlobalModal,
    // FadeTransition,
    DashboardFooter,
    CartSidebar: defineAsyncComponent(() => import('@/views/Cart/Sidebar.vue')),
  },
  data() {
    return {
      /**
       * @type {object}
       * @property {boolean} show
       * @property {string} message
       */
      globalAlert: {
        show: false,
        message: '',
      },
      dismiss: useLocalStorage('globalAlertDismiss', false),
    };
  },
  created() {
    const globalAlert = import.meta.env.VITE_APP_EMERGENCY_MESSAGE;
    if (globalAlert && !this.dismiss) {
      this.globalAlert.show = true;
      this.globalAlert.message = globalAlert;
    } else if (!globalAlert) {
      // reset dismiss if there is no global alert
      // because it might be a new alert some time later
      if (this.dismiss) {
        this.dismiss = false;
      }
    }
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      const isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
    dismissGlobalAlert() {
      this.globalAlert.show = false;
      this.dismiss = true;
    },
  },
};
</script>

<style lang="scss">
.mobile-app {
  .dashboard-content {
    margin-top: 60px;
  }

  .global-alerts {
    margin-top: 60px !important;

    ~ .dashboard-content {
      margin-top: 0;
    }
  }
}

.dashboard-content {
  min-height: calc(100vh - 184px - 60px); // 184 = footer, 60 = header
}

// Custom height
.messages {
  .dashboard-content {
    min-height: calc(100vh - 60px);
  }
}

.main-content {
  .container-fluid {
    max-width: 2000px;
  }
}

#dismiss-global-alert {
  &:hover {
    background-color: inherit;
    color: inherit;
  }
}

.global-alerts {
  z-index: 1049;
  .alert-text {
    width: 100%;
    .alert-message {
      overflow-wrap: break-word;
      max-width: 90%;
      min-width: 50%;
    }
  }
}
</style>
