import axios from 'axios';
import store from '../../store';
import router from '../../router';
import NProgress from 'nprogress';
import axiosRetry from 'axios-retry';

const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_CRM_API_BASE_URL;

/**
 *  Live CRM will use production API key linked with existing RMVFY API (api.rmvfy.com)
 *  For development & staging, we will use a hardcoded token token
 */
const TEMP_TOKEN = import.meta.env.VITE_APP_REMOVIFY_CRM_TMP_TOKEN || null;

const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 2 });

client.interceptors.request.use(
  (config) => {
    const token = TEMP_TOKEN || store.getters['auth/getToken'];
    const customerId = store.getters['user/getCustomerID'];

    if (token) config.headers['X-Removify-Token'] = token;
    if (customerId) config.headers['X-Removify-Customer-Id'] = customerId;

    NProgress.start();
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    // Unauthorised User
    if (
      error.response?.status === 404 &&
      import.meta.env.VITE_APP_ENV === 'production'
    ) {
      // Check if the page is public.
      if (
        router &&
        router.history &&
        router.history.current &&
        router.history.current.meta &&
        !router.history.current.meta.guest
      ) {
        window.location = '/logout?hr=true';
      }
    }

    // Everything else (e.g. 500)
    // Force progressbar done.
    NProgress.done();
    return Promise.reject(error);
  },
);

/**
 *  Removify CRM v2
 */
export default {
  /**
   *  CUSTOMER BILLING
   */

  /**
   * @name CustomerPaymentMethod
   * @description add a payment method
   * @param {object} data
   * @method POST
   */
  createCustomerPayment(data) {
    return client.post('/customer/payment_methods', data);
  },
};
