import client from '@/services/api/RemovifyServiceV2/config.js';

const prefix = '/billing';

export const verifyOrderSubmission = (organization_id: number) => {
  return client.post(`${prefix}/pre-order-verify/${organization_id}`);
};

export default {
  verifyOrderSubmission,
};
