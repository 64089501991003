/**
 * Loading Composable
 * @note This is a composable function to handle loading state with NProgress
 * @see https://vueuse.org/useNProgress
 */
import { useNProgress } from '@vueuse/integrations/useNProgress';

/**
 * @note use global loading state, so we can share the loading state across the app
 */
const { isLoading, progress, start, done, remove } = useNProgress();
export const useGlobalLoading = () => {
  // TODO: Can do a calculation like if total 5 request, then 100/5 = 20% per request etc.

  return {
    isLoading,
    progress,
    start,
    done,
    remove,
  };
};
export default useGlobalLoading;
