<template>
  <div>
    <p>You currently have overdue invoices on your account.</p>
    <p>
      Please provide immediate payment to ensure that service can continue
      without disruption, and avoid the possibility of past or pending removals
      being reinstated by the publisher.
    </p>
    <div class="mt-6 flex justify-end">
      <base-button
        type="primary"
        size="lg"
        class="btn-min-width"
        label="Go to Billing"
        @click="gotoBilling()"
      />
      <base-button
        type="primary"
        size="lg"
        class="btn-min-width"
        outline
        label="Contact Us"
        @click="gotoMessages()"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoMessages() {
      this.$store.commit('modal/RESET_STATE');
      this.$router.push({ name: 'messages' });
    },
    gotoBilling() {
      this.$store.commit('modal/RESET_STATE');
      this.$router.push({ name: 'invoices' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
