export const Rewards = [
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import('@/views/Rewards/Index.vue'),
    meta: {
      title: 'Earn Rewards',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['affiliate.read'],
      menu: 'primary-bottom',
      icon: 'ri ri-dollar',
    },
  },
];
