import type { App } from 'vue';
import SidebarItem from './SidebarItem.vue';
import Sidebar from './Sidebar.vue';
import { useSidebarStore } from './useSidebarStore';

const sidebarStore = useSidebarStore();
export const install = (app: App) => {
  app.config.globalProperties.$sidebar = sidebarStore;
  app.component('SideBar', Sidebar);
  app.component('SidebarItem', SidebarItem);
};

export default install;
