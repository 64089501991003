/**
 * Get the number of slots used by a campaign
 * @description This function would return contact if type is sms/email and rating response if type is page
 * @param {'email'|'sms'|'page'} type campaign type
 * @param {number?} slots_used slots used by the campaign
 * @param {number?} rating_total rating total of the campaign
 * @returns {number} slots_used|rating_total
 */
export const getSlotUsed = (type, slots_used, rating_total) => {
  if (type === 'page') {
    return rating_total;
  }
  return slots_used;
};

/**
 * Get the platforms from the publishers
 * @description This function would return the platforms from the publishers
 * @param {*} publishers
 * @returns {Array} platforms
 */
export const getPlatforms = (publishers) => {
  const platforms = [];

  publishers.forEach((publisher) => {
    if (!platforms.includes(publisher.name)) {
      platforms.push(publisher.name);
    }
  });
  return platforms;
};

/**
 * Get the groups from the publishers
 * @param {Array} publishersWithGroup
 * @returns {Array} groups
 */
export const getGroups = (publishersWithGroup) => {
  return publishersWithGroup.map(group => ({
    tag_id: group.tag_id ?? -1,
    tag_name: group.name ?? 'Ungrouped',
    editing: false,
  }));
};

export default {
  getSlotUsed,
  getPlatforms,
  getGroups,
};
