<template>
  <div :class="{ 'mobile-app': isMobile() }">
    <slot />
  </div>
</template>

<script>
import { isMobile } from '@/mixins/mobile.js';

export default {
  mixins: [isMobile],
};
</script>
