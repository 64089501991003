import api from '@/services';
import AuthError from '@/classes/AuthError.js';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    token: '',
    token_expire: '',
  };
};

export const state = getDefaultState();

export const getters = {
  isAuthenticated: (state) => {
    return state.token ? 1 : 0;
  },
  getToken: (state) => {
    return state.token;
  },
  getTokenExpire: (state) => {
    return state.token_expire;
  },
};

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_TOKEN_EXPIRE(state, expire) {
    state.token_expire = expire;
  },
  RESET_STATE(state) {
    localStorage.removeItem('user');
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  getAuthUserFromToken({ getters, commit }) {
    return new Promise((resolve, reject) => {
      const user =
        (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user'))) ||
        null;
      const token = api.jwt.decodeToken(user.token);
      const errorList = ['NotBeforeError', 'JsonWebTokenError'];

      if (!token) {
        reject(
          new AuthError('Not a valid token', {
            code: 90210,
          }),
        );
      }

      if (token && token.name && errorList.indexOf(token.name) > 0) {
        reject(
          new AuthError('Invalid token', {
            code: 90209,
          }),
        );
      }

      /*
       * return the user object if the token is valid
       */
      if (!getters.isAuthenticated || getters.getToken !== user.token) {
        commit('SET_TOKEN', user.token);
        commit('SET_TOKEN_EXPIRE', token.expire);
        resolve({
          authenticated: true,
          user,
        });
      } else {
        resolve({
          authenticated: true,
          user,
        });
      }
    });
  },
};
