import { ping } from './ping';
import organization from './organization';
import group from './group';
import monitor from './monitor';
import contact from './Amplify/contact';
import review from './review';
import user from './user';
import billing from './billing';

export default {
  billing,
  ping,
  organization,
  group,
  monitor,
  contact,
  review,
  user,
};
