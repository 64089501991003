import client from '@/services/api/RemovifyServiceV2/config.js';
/**
 * Get the user organization
 * @param {number} organization_id
 * @param {boolean} isDemo
 * @returns {Promise}
 */
export const getOrganization = (organization_id, isDemo = false) => {
  return client.get(`/users/organization/${organization_id}`, {
    params: {
      demo: isDemo ? '1' : '0',
    },
  });
};

export default {
  getOrganization,
};
