<template>
  <label class="wrapper">
    <slot>
      {{ label }}
    </slot>
    <input
      class="checkbox"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <slot name="trigger">
      <span class="checkmark"></span>
    </slot>
  </label>
</template>

<script>
/**
 * @author [Jonarod](https://gist.github.com/Jonarod)
 * @source https://gist.github.com/Jonarod/7ff2fe4f81aae39e431aa7a08ce815bc
 * @usage
 *  <CheckBox label="Foo" value="foo" v-model="MySelectedValues" />
 */
export default {
  name: 'BaseCheckboxV2',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    value: {
      description:
        'Value of the checkbox, when checked it will be added to the modelValue array',
    },
    modelValue: {
      default: '',
      description:
        'The model value, use as v-modal="", do NOT direct give this prop a value',
    },
    label: {
      type: String,
      default: ' ',
      description: 'Label of the checkbox',
    },
    trueValue: { default: true, description: 'Customized true check' },
    falseValue: { default: false, description: 'Customized false check' },
    single: {
      type: Boolean,
      default: false,
      description: 'If true, the checkbox will act as a radio button',
    },
  },
  computed: {
    isChecked() {
      if (this.single) {
        return this.modelValue === this.value;
      }
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (this.single) {
        this.$emit('update:modelValue', isChecked ? this.value : null);
        return;
      }
      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('update:modelValue', newValue);
      } else {
        this.$emit(
          'update:modelValue',
          isChecked ? this.trueValue : this.falseValue,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Customize the label (the wrapper) */
.wrapper {
  margin: 0;
  display: block;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.875rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 1rem;
    width: 1rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-400;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    background-color: white;
  }

  input:checked ~ .checkmark {
    background-color: white;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    width: 0.25rem;
    height: 0.5rem;
    border: solid $blue;
    border-width: 0 0.1rem 0.1rem 0;
  }
}
</style>
