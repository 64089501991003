import rmvfy from '@/services/api/RemovifyService.js';
import rmvfyV2 from '@/services/api/RemovifyServiceV2';
import rmvfyV3 from '@/services/api/RemovifyServiceV3';
import media from '@/services/api/MediaService.js';
import crm from '@/services/api/CRMService.js';
import jwt from '@/services/api/JWTService.js';
import auth from '@/services/api/Auth.js';
import googlemaps from '@/services/api/GoogleMaps.js';
import gmb from '@/services/api/GoogleMyBusiness.js';
import crmV2 from '@/services/api/CRMServiceV2.js';
import external from '@/services/api/External.js';

export default {
  jwt,
  rmvfy,
  rmvfyV2,
  rmvfyV3,
  crm,
  auth,
  googlemaps,
  media,
  gmb,
  crmV2,
  external,
};
