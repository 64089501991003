import api from '@/services';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    contact: null,
    contacts: [],
    contactsTotal: 0,

    query: {
      page: null,
      limit: null,
      sort: null,
      search: null,
    },
    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {};

export const mutations = {
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },
  SET_CONTACTS_TOTAL(state, total) {
    state.contactsTotal = total;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchContacts({ commit, state }, { query, campaignCode }) {
    commit('SET_LOADING', true);

    // Query
    let filter;
    if (query) {
      filter = query;
      commit('SET_QUERY', query);
    } else {
      filter = state.query;
    }

    const params = {
      page: filter.page ? filter.page : 1,
      limit: filter.limit ? filter.limit : 10,
      order: filter.sort ? filter.sort : 'last_activity_date:desc',
      search: filter.search ? filter.search : '',
      substatus: filter.substatus ? filter.substatus : '',
    };

    try {
      const response = await api.rmvfy.getAmplifyContacts(campaignCode, params);
      if (response.data && response.data.data) {
        commit('SET_CONTACTS', response.data.data);
        commit('SET_CONTACTS_TOTAL', response.data.total);
        return response;
      } else throw response;
    } catch (error) {
      return error;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
