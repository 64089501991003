import axios from 'axios';
import * as Sentry from '@sentry/vue';
import store from './../../store';
import router from './../../router';
import NProgress from 'nprogress';
import axiosRetry from 'axios-retry';
import { getErrorMessage } from '@/utils/axios';

const API_VERSION = 'v0';
const BASE_URL = import.meta.env.VITE_APP_REMOVIFY_API_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL + '/' + API_VERSION,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

axiosRetry(client, { retries: 0 });

client.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/getToken'];
    if (token) config.headers['X-Removify-Token'] = token;

    // Demo
    const isDemoMode = store.getters['user/isDemoMode'];
    const demoName = localStorage.getItem('_demoname');

    if (isDemoMode && demoName) {
      config.headers['X-Demo-Name'] = demoName;
    }

    NProgress.start();
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    // Unauthorised User
    if (error.response?.status === 401) {
      Sentry.addBreadcrumb({
        category: 'unauthorized',
        message: 'Unauthorized User',
        level: 'info',
      });
      // Check if the page is public.
      if (
        router &&
        router.history &&
        router.history.current &&
        router.history.current.meta &&
        !router.history.current.meta.guest
      ) {
        window.location = '/logout?hr=true';
      }
    }

    // Everything else (e.g. 500)
    NProgress.done();
    // Force progressbar done.
    // since we are not rejecting the promise, we need to capture the error with Sentry
    const e = getErrorMessage(error);

    if (!e.ignore) {
      if (e.data) {
        Sentry.addBreadcrumb({
          category: 'api',
          message: 'API Error check the data for more info',
          level: 'error',
          data: e.data,
        });
      }

      Sentry.captureException(e.error);
    }
    return error;
  },
);

export default {
  /**
   * Ping
   */
  ping() {
    console.warn(
      '[DEPRECATED] This version of ping is deprecated, use crm Version 2 instead.',
    );
    return client.get('/ping');
  },

  /**
   * Ticket - getTickets
   * @param {object} params
   */
  getTickets(params) {
    let queryStr = '?';
    if (params.limit) queryStr += 'limit=' + params.limit;
    if (params.page) queryStr += '&page=' + params.page;
    if (params.status) queryStr += '&status=' + params.status;
    if (params.search)
      queryStr += '&search=' + encodeURIComponent(params.search);
    if (params.group) queryStr += '&group=' + encodeURIComponent(params.group);
    if (params.order) queryStr += '&order=' + params.order;
    if (params.publisher) queryStr += '&publisher=' + params.publisher;
    if (params.csv) queryStr += '&csv=' + params.csv;
    return client.get(
      `/tickets/organization/${store.getters['user/getOID']}${queryStr}`,
    );
  },

  /**
   * Ticket - getTicketStatus
   */
  getTicketStatus() {
    return client.get('/tickets/status');
  },

  /**
   * Ticket - getTicket
   * @param {string} id
   */
  getTicket(id) {
    return client.get(
      `/tickets/organization/${store.getters['user/getOID']}/${id}`,
    );
  },

  /**
   * Ticket - updateTicketNote
   * @param {string} id
   * @param {object} data
   */
  updateTicketNote(id, data) {
    return client.put(
      `/tickets/organization/${store.getters['user/getOID']}/${id}/note`,
      data,
    );
  },

  /**
   * Tickets - getGroups
   * @param {string} oid
   */
  getGroups(oid) {
    return client.get(`/tickets/groups/${oid}`);
  },

  /**
   * Reviews - createPublisher
   * @param {object} data
   */
  createPublisher(data) {
    console.warn(
      '[DEPRECATED] This version of createPublisher is deprecated, use removify Version 2 instead.',
    );
    return client.post(`/reviews/${store.getters['user/getOID']}/new`, data);
  },

  /**
   * Google My Business - createAccessToken
   * @param {object} data
   */
  createAccessToken(data) {
    return client.post(`/gmb/code/${store.getters['user/getOID']}`, data);
  },

  /**
   * Google My Business - createLocation
   * @param {object} data
   */
  createLocation(data) {
    return client.post(`/gmb/location/${store.getters['user/getOID']}`, data);
  },

  /**
   * Google My Business - previewGMB
   * @param {string} urlId
   * @returns
   */
  previewGMB(urlId) {
    return client.get(`/gmb/preview/${store.getters['user/getOID']}/${urlId}`);
  },

  /**
   * Google My Business - convertGMB
   * @param {object} data
   */
  convertGMB(data) {
    return client.post(`/gmb/convert/${store.getters['user/getOID']}`, data, {
      timeout: 0,
    });
  },

  /**
   * Google My Business - send invite to USA GMB
   * @param {object} data
   */
  inviteGMB(data) {
    return client.post(
      `/gmb/admininvite/${store.getters['user/getOID']}`,
      data,
      { timeout: 0 },
    );
  },

  /**
   * Google My Business - runGMBReviews
   * @note this fetches all the latest GMB reviews from the API
   */
  runGMBReviews() {
    return client.get(`/gmb/run/${store.getters['user/getOID']}`);
  },

  /**
   * Google My Business - getGMBReply
   * @param {string} reviewId
   */
  getGMBReply(reviewId) {
    return client.get(`/gmb/reply/${store.getters['user/getOID']}/${reviewId}`);
  },

  /**
   * Google My Business - updateGMBReply
   * @param {string} reviewId
   * @param {object} data
   */
  updateGMBReply(reviewId, data) {
    return client.post(
      `/gmb/reply/${store.getters['user/getOID']}/${reviewId}`,
      data,
    );
  },

  /**
   * Google My Business - deleteGMBReply
   * @param {string} reviewId
   */
  deleteGMBReply(reviewId) {
    return client.delete(
      `/gmb/reply/${store.getters['user/getOID']}/${reviewId}`,
    );
  },

  /**
   * Enquiry - getEnquiryComments
   * @param {object} data
   */
  getEnquiryComments(params) {
    return client.get(
      `/enquiry/comments/${store.getters['user/getOID']}?order=date:desc&limit=${params.limit}&cache=${params.cache}`,
    );
  },

  /**
   * Enquiry - createEnquiry
   * @param {formData} data
   */
  createEnquiry(data) {
    return client.post(`/enquiry/new/${store.getters['user/getOID']}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /**
   * User - getUser
   * @param {string} id
   */
  getUsers(id) {
    return client.get(`/users/${id}`);
  },

  /**
   * User - checkDemoMode
   */
  getDemoMode() {
    return client.get('/users/demo');
  },

  /**
   * User - updateDemo
   */
  updateDemoMode(data) {
    return client.put('/users/demo', data);
  },

  /**
   * User - getOrganizations
   * @param {boolean} isDemo
   */
  getOrganizations(isDemo = false) {
    const demoQuery = isDemo ? '&demo=1' : '';
    return client.get('/users/organizations?start=0' + demoQuery);
  },

  /**
   * User - getUserSubscriptions
   */
  getUserSubscriptions() {
    return client.get(`/tiers/${store.getters['user/getOID']}`);
  },

  /**
   * User - upgradeUserSubscription
   */
  upgradeUserSubscription(data) {
    return client.post(`/tiers/${store.getters['user/getOID']}/upgrade`, data);
  },

  /**
   * Plans - getSubscriptionList
   */
  getSubscriptionList() {
    return client.get(`/tiers`);
  },

  /**
   * User - searchOrganizations
   * @param {string} query
   * @param {boolean} isDemo
   */
  searchOrganizations(query, isDemo = false) {
    const demoQuery = isDemo ? '&demo=1' : '';
    query = encodeURIComponent(query);
    return client.get(
      `/users/organizations?limit=10&start=0&search=${query}${demoQuery}`,
    );
  },

  /**
   * User - getUserScopes
   */
  getUserScopes() {
    return client.get('/scopes');
  },

  /**
   * User - getUserSettings
   */
  getUserSettings() {
    return client.get('/users/settings');
  },

  /**
   * Get unlayer verified signature for front-end consumption
   */
  getUserUnlayerKey() {
    return client.get('/users/unlayer/signature');
  },

  /**
   * User - getUserSettings
   * @param {object} data
   */
  updateUserSettings(data) {
    return client.put('/users/settings', data);
  },

  /**
   * User - forgotPassword
   * @param {object} data
   */
  forgotPassword(data) {
    return client.put('/users/forgotpassword', data);
  },

  /**
   * User - updateUser
   * @param {string} userId
   * @param {object} data
   */
  updateUser(userId, data) {
    return client.put(`/users/${userId}`, data);
  },

  /**
   * Amplify - getAmplifyCampaigns
   * @param {object} params
   */
  getAmplifyCampaigns(params) {
    return client.get(`/amplify/${store.getters['user/getOID']}/campaign`, {
      params,
    });
  },

  /**
   * Amplify - createAmplify
   * @param {object} data
   */
  createAmplify(data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign`,
      data,
    );
  },

  /**
   * Amplify - getAmplifyCampaignUsage
   */
  getAmplifyCampaignUsage(campaign_code) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaign_code}/usage`,
    );
  },

  /**
   * Amplify - duplicateAmplify
   * @param {string} campaignCode
   */
  duplicateAmplify(campaignCode) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/clone`,
    );
  },

  /**
   * Amplify - updateAmplify
   * @param {string} campaignCode
   * @param {object} data
   */
  updateAmplify(campaignCode, data) {
    return client.put(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}`,
      data,
    );
  },

  /**
   * Amplify - deleteAmplify
   * @param {string} campaignCode
   */
  deleteAmplify(campaignCode) {
    return client.delete(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}`,
    );
  },

  /**
   * Amplify - getAmplify
   * @param {string} campaignCode
   */
  getAmplify(campaignCode) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}`,
    );
  },

  /**
   * Amplify - activateAmplify
   * @param {string} campaignCode
   * @param {object} data
   */
  activateAmplify(campaignCode, data) {
    return client.patch(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/`,
      data,
    );
  },

  /**
   * Amplify - getAmplifyAllFeedback
   * @param {object} params
   */
  getAmplifyAllFeedback(params) {
    let queryStr = '?';
    if (params.limit) queryStr += 'limit=' + params.limit;
    if (params.campaign_code)
      queryStr += '&campaign_code=' + params.campaign_code;
    if (params.page) queryStr += '&page=' + params.page;
    if (params.search)
      queryStr += '&search=' + encodeURIComponent(params.search);
    if (params.order) queryStr += '&order=' + params.order;
    if (params.csv) queryStr += '&csv=' + params.csv;
    if (params.start_date) queryStr += '&start_date=' + params.start_date;
    if (params.end_date) queryStr += '&end_date=' + params.end_date;
    if (params.rating) {
      const ratings = params.rating.split(',');
      const filteredRatings = [];
      for (const i in ratings) {
        const newVal = Number.parseInt(ratings[i]);
        if (newVal && typeof newVal === 'number') {
          filteredRatings.push(newVal);
        }
      }
      if (filteredRatings.length > 0) {
        queryStr += '&filter[rating]=' + filteredRatings.join();
      }
    }
    if (params.status) queryStr += '&filter[status]=' + params.status;
    if (params.note) queryStr += '&filter[note]=' + params.note;
    if (params.private) queryStr += '&filter[private]=' + params.private;
    if (params.posted) queryStr += '&filter[posted]=' + params.posted;
    return client.get(
      `/amplify/${store.getters['user/getOID']}/feedback${queryStr}`,
    );
  },

  updateAmplifyFeedbackNote(campaignCode, feedbackCode, note) {
    return client.put(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/feedback/${feedbackCode}/note`,
      {
        note,
      },
    );
  },

  updateAmplifyFeedbackStatus(campaignCode, feedbackCode, status) {
    return client.put(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/feedback/${feedbackCode}/status`,
      {
        status,
      },
    );
  },

  /**
   * Amplify - getAmplifyPhoneNumbers
   */
  getAmplifyPhoneNumbers() {
    return client.get(`/amplify/${store.getters['user/getOID']}/phonenumber`);
  },

  /**
   * Amplify - buyAmplifyPhoneNumber
   * @param {data} object
   */
  buyAmplifyPhoneNumber(data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/phonenumber`,
      data,
    );
  },

  /**
   * Image - uploadImage
   * @param {file} image
   */
  uploadImage(image) {
    return client.post(`/upload/image`, image);
  },

  /**
   * Image - uploadImage
   * @param {string} file_id
   */
  getImage(file_id) {
    return client.get(`/upload/image/${file_id}`);
  },

  /**
   * Image - removeImage
   * @param {string} file_id
   */
  removeImage(file_id) {
    return client.delete(`/upload/${file_id}`);
  },

  /**
   * Report - getReportLocation
   * @param {object} params
   */
  getReportLocation(params) {
    let queryStr = '?limit=' + params.limit;
    if (params.page) queryStr += '&page=' + params.page;
    if (params.search)
      queryStr += '&search=' + encodeURIComponent(params.search);
    if (params.group) queryStr += '&group=' + encodeURIComponent(params.group);
    if (params.order) queryStr += '&order=' + params.order;
    if (params.csv) queryStr += '&csv=' + params.csv;
    if (params.start_date) queryStr += '&start_date=' + params.start_date;
    if (params.end_date) queryStr += '&end_date=' + params.end_date;
    let filterList = '';
    if (params.filter) {
      const filterKeys = Object.keys(params.filter);
      const filterLength = filterKeys.length;
      for (let i = 0; i < filterLength; i++) {
        if (params.filter[filterKeys[i]].length > 0) {
          filterList += '&filter[' + filterKeys[i] + ']=';
          filterList += encodeURIComponent(params.filter[filterKeys[i]].join());
        }
      }
    }
    return client.get(
      `/reports/${store.getters['user/getOID']}/locations${queryStr}${filterList}`,
    );
  },

  /**
   * Report - deleteReportLocations
   */
  deleteReportLocations() {
    return client.delete(`/reports/${store.getters['user/getOID']}/locations`);
  },

  /**
   * Report - getReportAmplifyFeedback
   * @param {object} params
   */
  getReportAmplifyFeedback(params) {
    let queryStr = '?';
    if (params.limit) queryStr += 'limit=' + params.limit;
    if (params.page) queryStr += '&page=' + params.page;
    if (params.search)
      queryStr += '&search=' + encodeURIComponent(params.search);
    if (params.group) queryStr += '&group=' + encodeURIComponent(params.group);
    if (params.order) queryStr += '&order=' + params.order;
    if (params.csv) queryStr += '&csv=' + params.csv;
    if (params.start_date) queryStr += '&start_date=' + params.start_date;
    if (params.end_date) queryStr += '&end_date=' + params.end_date;
    if (params.refresh) queryStr += '&refresh=1';
    return client.get(
      `/reports/${store.getters['user/getOID']}/feedback${queryStr}`,
    );
  },

  /**
   * Report - getReportAmplifyFeedback
   * @param {string} campaignCode
   * @param {object} params
   */
  getReportAmplifyFeedbackByCampaign(campaignCode, params) {
    let queryStr = '';
    if (params.start_date) queryStr += '?start_date=' + params.start_date;
    if (params.end_date) queryStr += '&end_date=' + params.end_date;
    return client.get(
      `/reports/${store.getters['user/getOID']}/feedback/${campaignCode}${queryStr}`,
    );
  },

  /**
   * Report - getReportLocationByURL
   * @param {string} url_id
   * @param {string} start
   * @param {string} end
   */
  getReportLocationByURL(url_id, start, end) {
    return client.get(
      `/reports/${store.getters['user/getOID']}/locations?monitors=${url_id}&start_date=${start}&end_date=${end}`,
    );
  },

  /**
   * Report - getReportGMB
   * @param {string} accountId
   * @param {string} start
   * @param {string} end
   */
  getReportGMB(accountId, start, end) {
    return client.get(
      `/reports/${store.getters['user/getOID']}/insights/${accountId}?start_date=${start}&end_date=${end}`,
    );
  },

  /**
   * Report - getReportReviews
   * @param {string} url_id
   * @param {string} start
   * @param {string} end
   */
  getReportReviews(url_id, start, end) {
    return client.get(
      `reports/${store.getters['user/getOID']}/reviews/${url_id}?start_date=${start}&end_date=${end}`,
    );
  },

  /**
   * Reviews - getReviewMonitors
   */
  getReviewMonitors(params) {
    let queryStr = '?';
    if (params.limit) queryStr += 'limit=' + params.limit;
    if (params.search)
      queryStr += '&search=' + encodeURIComponent(params.search);
    if (params.group) queryStr += '&group=' + params.group;
    return client.get(`/publishers/${store.getters['user/getOID']}${queryStr}`);
  },

  /**
   * Reviews - updateReviewMonitorGroups
   */
  updateReviewMonitorGroups(data) {
    return client.post(
      `/publishers/${store.getters['user/getOID']}/groups`,
      data,
    );
  },

  /**
   * Review - getReview
   */
  getReview(id) {
    return client.get(`/reviews/${store.getters['user/getOID']}/review/${id}`);
  },

  /**
   * Reviews - updateReviewMonitors
   */
  updateReviewMonitors(data) {
    return client.put(`/publishers/${store.getters['user/getOID']}`, data);
  },

  /**
   * @name getCustomerReviews
   * @param {object} params
   */
  getCustomerReviews(params) {
    let queryStr = '?';
    if (params.limit) queryStr += 'limit=' + params.limit;
    if (params.page) queryStr += '&page=' + params.page;
    if (params.order) queryStr += '&order=' + params.order;
    if (params.search)
      queryStr += '&search=' + encodeURIComponent(params.search);
    if (params.group) queryStr += '&filter[groups]=' + params.group;
    if (params.url_id) queryStr += '&filter[url_id]=' + params.url_id;
    if (params.start_date) queryStr += '&start_date=' + params.start_date;
    if (params.end_date) queryStr += '&end_date=' + params.end_date;
    if (params.rating) {
      const ratings = params.rating.split(',');
      const filteredRatings = [];
      for (const i in ratings) {
        const newVal = Number.parseInt(ratings[i]);
        if (newVal && typeof newVal === 'number') {
          filteredRatings.push(newVal);
        }
      }
      if (filteredRatings.length > 0) {
        queryStr += '&filter[rating]=' + filteredRatings.join();
      }
    }
    if (params.review_has_text)
      queryStr += '&filter[review_has_text]=' + params.review_has_text;
    if (params.publisher) queryStr += '&filter[publisher]=' + params.publisher;
    if (params.available) queryStr += '&filter[available]=' + params.available;
    if (params.refresh) queryStr += '&refresh=' + params.refresh;
    return client.get(`reviews/${store.getters['user/getOID']}${queryStr}`);
  },

  /**
   * Amplify - getAmplifyFeedback
   * @param {string} campaignCode
   * @param {string} params
   */
  getAmplifyFeedback(campaignCode, params) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/feedback?` +
      params,
    );
  },

  /**
   * Amplify - importAmplifyContactsByCSV
   * @param {string} campaignCode
   * @param {file} data
   */
  importAmplifyContactsByCSV(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/upload-csv`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },

  /**
   * Amplify - confirmAmplifyContactsByCSV
   * @param {string} campaignCode
   * @param {file} data
   */
  confirmAmplifyContactsByCSV(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/upload-csv-confirmation`,
      data,
    );
  },

  /**
   * Amplify - importAmplifyContactsByFlatFile
   * @param {string} campaignCode
   * @param {object} data
   */
  importAmplifyContactsByFlatFile(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/upload-by-flatfile`,
      data,
    );
  },

  /**
   * Amplify - createAmplifyContact
   * @param {string} campaignCode
   * @param {object} data
   */
  createAmplifyContact(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contact`,
      data,
    );
  },

  /**
   * Amplify - deleteAmplifyContact
   * @param {string} campaignCode
   * @param {string} contactId
   */
  deleteAmplifyContact(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/delete`,
      data,
    );
  },

  /**
   * Amplify - unsubscribeAmplifyContacts
   * @param {string} campaignCode
   * @param {object} data
   */
  unsubscribeAmplifyContacts(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/unsubscribe`,
      data,
    );
  },

  /**
   * Amplify - pauseAmplifyContacts
   * @param {string} campaignCode
   * @param {object} data
   */
  pauseAmplifyContacts(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/pause`,
      data,
    );
  },

  /**
   * Amplify - activateAmplifyContacts
   * @param {string} campaignCode
   * @param {object} data
   */
  activateAmplifyContacts(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts/active`,
      data,
    );
  },

  /**
   * Amplify - getAmplifyContacts
   * @param {string} campaignCode
   * @param {string} params
   */
  getAmplifyContacts(campaignCode, params) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contact`,
      {
        params,
      },
    );
  },

  /**
   * Amplify - getAmplifyContact
   * @param {string} campaignCode
   * @param {string} contactCode
   */
  getAmplifyContact(campaignCode, contactCode) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contact/${contactCode}`,
    );
  },

  /**
   * Amplify - updateAmplifyContact
   * @param {string} campaignCode
   * @param {string} contactCode
   * @param {object} data
   */
  updateAmplifyContact(campaignCode, contactCode, data) {
    return client.put(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contact/${contactCode}`,
      data,
    );
  },

  /**
   * Amplify - getAmplifyContactsQueue
   * @param {string} campaignCode
   */
  getAmplifyContactsQueue(campaignCode, params) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/contacts-queue${params}`,
    );
  },

  /**
   * Amplify - getAmplifyTemplates
   * @param {string} campaignCode
   */
  getAmplifyTemplates(campaignCode) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/templates`,
    );
  },

  /**
   * Amplify - getAmplifyTemplatesList
   */
  getAmplifyTemplatesList() {
    return client.get(`/amplify/${store.getters['user/getOID']}/templates`);
  },

  /**
   * Amplify - getAmplifyRefreshTemplatesList
   */
  getAmplifyRefreshTemplatesList() {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/templates-refresh`,
    );
  },

  /**
   * Amplify - updateAmplifyTemplate
   * @param {string} campaignCode
   * @param {string} templateCode
   * @param {object} data
   */
  updateAmplifyTemplate(campaignCode, templateCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/template/${templateCode}`,
      data,
    );
  },

  /**
   * Amplify - getAmplifyContentSettings
   * @param {string} campaignCode
   */
  getAmplifyContentSettings(campaignCode) {
    return client.get(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/setting`,
    );
  },

  /**
   * Amplify - createAmplifyContentSettings
   * @param {string} campaignCode
   * @param {object} data
   */
  createAmplifyContentSettings(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/setting`,
      data,
    );
  },

  /**
   * Amplify - updateAmplifyContentSettings
   * @param {string} campaignCode
   * @param {string} settingCode
   * @param {object} data
   */
  updateAmplifyContentSettings(campaignCode, settingCode, data) {
    return client.put(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/setting/${settingCode}`,
      data,
    );
  },

  /**
   * Amplify - sendAmplifyTestEmail
   * @param {string} campaignCode
   * @param {object} data
   */
  sendAmplifyTestEmail(campaignCode, data) {
    return client.post(
      `/amplify/${store.getters['user/getOID']}/campaign/${campaignCode}/simulation`,
      data,
    );
  },

  /**
   * Amplify Widget - createWidget
   * @param {object} data
   */
  createWidget(data) {
    return client.post(
      `/widgets/${store.getters['user/getOID']}/reviews/settings`,
      data,
    );
  },

  /**
   * Amplify Widget - updateWidget
   * @param {string} widgetId
   * @param {object} data
   */
  updateWidget(widgetId, data) {
    return client.put(
      `/widgets/${store.getters['user/getOID']}/reviews/settings/${widgetId}`,
      data,
    );
  },

  /**
   * Amplify Widget - getWidget
   * @param {string} widgetId
   */
  getWidget(widgetId) {
    return client.get(
      `/widgets/${store.getters['user/getOID']}/reviews/settings/${widgetId}`,
    );
  },

  /**
   * Amplify Widget - getWidgets
   * @param {string} widgetId
   */
  getWidgets() {
    return client.get(
      `/widgets/${store.getters['user/getOID']}/reviews/settings`,
    );
  },

  /**
   * Amplify campaign - create custom links
   * @param {string} params
   * @param {string} params.org_id
   * @param {string} params.campaign_code
   * @param {object} data
   * @returns {Promise} Promise object represents the response
   */
  createCustomLinks({ org_id, campaign_code }, data) {
    return client.post(
      `/amplify/${org_id}/campaign/${campaign_code}/custom-links`,
      data,
    );
  },

  /**
   * Amplify campaign - getCustomLinkReviewMonitors
   * @param {string} org_id
   * @param {string} campaign_code
   * @returns {Promise} Promise object represents the response
   */
  getCustomLinkReviewMonitors(org_id, campaign_code) {
    return client.get(
      `/amplify/${org_id}/campaign/${campaign_code}/custom-links`,
    );
  },

  /**
   * Amplify campaign - Update custom links
   * @param {string} org_id
   * @param {string} campaign_code
   * @returns {Promise} Promise object represents the response
   */
  updateCustomLinks(org_id, campaign_code, data) {
    const params = {
      name: data.name,
      description: data.description,
      link: data.link,
    };

    return client.put(
      `/amplify/${org_id}/campaign/${campaign_code}/custom-links/${data.id}`,
      params,
    );
  },

  /**
   * Amplify campaign - Delete custom links
   * @param {string} org_id
   * @param {string} campaign_code
   * @param {string} custom_link_id
   * @returns {Promise} Promise object represents the response
   */
  deleteCustomLinks(org_id, campaign_code, custom_link_id) {
    return client.delete(
      `/amplify/${org_id}/campaign/${campaign_code}/custom-links/${custom_link_id}`,
    );
  },

  /**
   * Timezones - getTimezones
   */
  getTimezones() {
    return client.get('/timezones');
  },

  /**
   * FAQ - getFAQS
   */
  getFAQS() {
    return client.get('/faqs?limit=35');
  },

  /**
   * Invoice
   */
  getInvoiceContact() {
    return client.get(`/invoices/${store.getters['user/getOID']}/contact`);
  },
};
