import client from '@/services/api/RemovifyServiceV2/config.js';
import store from '@/store';

/**
 * Get the user notification settings
 */
export const getUserNotificationSettings = () => {
  return client.get(
    `/users/${store.getters['user/getUserId']}/customer/${store.getters['user/getCustomerID']}/settings/notification`,
  );
};

/**
 * Update the user notification settings
 */
export const updateUserNotificationSettings = (data) => {
  return client.put(
    `/users/${store.getters['user/getUserId']}/customer/${store.getters['user/getCustomerID']}/settings/notification`,
    data,
  );
};

/**
 * Verify the signature for a user
 * If the signature is valid, the user will be logged in
 */
export const verifySignature = (userId, signature) => {
  return client.get(`/auth/signature/${userId}/${signature}`);
};

export default {
  getUserNotificationSettings,
  updateUserNotificationSettings,
  verifySignature,
};
