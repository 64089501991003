import { addBreadcrumb } from '@sentry/vue';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { ZodError } from 'zod';
export const useError = () => {
  /**
   * Set error message for axios error
   * @note This is a composable function to handle error message for axios error
   * we also need to add breadcrumb to sentry
   * @param error
   * @param defaultMessage
   * @returns
   */
  const setErrorMessage = (
    error: AxiosError | Error,
    defaultMessage = 'Something went wrong, please try again later.',
  ) => {
    if (error.message === 'Network Error' || error.name === 'NetworkError') {
      addBreadcrumb({
        category: 'NetworkError',
        message: 'Network Error',
        level: 'error',
        data: error,
      });
      return 'Network Error: ' + defaultMessage;
    }

    if (axios.isAxiosError(error)) {
      addBreadcrumb({
        category: 'Axios Error',
        level: 'error',
        data: error.response,
      });
      return error.response?.data;
    }

    return defaultMessage;
  };

  const addBreadcrumbForError = (error: AxiosError | ZodError | unknown) => {
    if (axios.isAxiosError(error)) {
      addBreadcrumb({
        category: 'AxiosError',
        message: 'Axios Error',
        level: 'info',
        data: error.response,
      });
      return;
    }
    if (error instanceof ZodError) {
      addBreadcrumb({
        category: 'ZodError',
        message: 'Zod error, check does backend return type match the schema',
        level: 'info',
        data: error,
      });
      return;
    }

    addBreadcrumb({
      category: 'CommonError',
      message: 'Error that is not AxiosError or ZodError',
      level: 'info',
      data: error as Error,
    });
  };
  return {
    setErrorMessage,
    addBreadcrumbForError,
  };
};
export default useError;
