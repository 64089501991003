export const Pages = [
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Privacy/Index.vue'),
    meta: {
      title: 'Privacy Policy',
      layout: 'Blank',
    },
  },
];
