import { getCurrentTime, tracking } from '.';

export const customerIoUpdateCartEvent = (currentCartCount: number, extraData: any) => {
  const currentTime = getCurrentTime();

  // Allow internal users too
  tracking(['cio', 'mixpanel'], {
    name: 'Update cart',
    timestamp: currentTime,
    data: {
      cart_items: currentCartCount,
      ...extraData,
    },
  }, true);
};
