import type { Module, ModuleMapping } from '@/types/Reporting/module';
import {
  moduleMappingSchema,
  reportingModuleSchema,
} from '@/types/Reporting/module';
import { z } from 'zod';

const _reportingSchema = z.object({
  reporting_id: z.number(),
  name: z.string(),
  created_by: z.string(),
  organization_id: z.number(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date(),
  data_updated_at: z.coerce.date(),
});

const _templateSchema = z.object({
  template_id: z.number(),
  name: z.string(),
  description: z.string(),
});

export const reportingTemplateSchema = z.array(
  _templateSchema.merge(z.object({ modules: moduleMappingSchema })),
);

export const reportingSchema = _reportingSchema.merge(
  z.object({ modules: z.array(reportingModuleSchema) }),
);

export const baseReportingSchema = z.object({
  reporting_id: z.number(),
  name: z.string(),
  created_by: z.string(),
});

export const reportingListSchema = z.object({
  data: z.array(baseReportingSchema),
  total: z.number(),
});

export type BaseReporting = z.infer<typeof baseReportingSchema>;
export type Reporting = z.infer<typeof _reportingSchema> & {
  modules: Module[];
};
export type ReportingListResponse = z.infer<typeof reportingListSchema>;
export type ReportingList = BaseReporting[];
export type ReportingTemplate = z.infer<typeof _templateSchema> & {
  modules: ModuleMapping;
};
export type ReportingTemplateList = ReportingTemplate[];
