import api from '@/services';
export const namespaced = true;

export const getDefaultState = () => {
  return {
    projectId: 27843, // unlayer project ID
    unlayerKey: import.meta.env.VITE_APP_UNLAYER_KEY || '',
    template: null,
    user: null,
    firstTemplateBackup: null,
  };
};

export const state = getDefaultState();

export const mutations = {
  SET_TEMPLATE(state, template) {
    state.template = template;
  },
  SET_FIRST_TEMPLATE_BACKUP(state, template) {
    state.firstTemplateBackup = template;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async setup({ commit, rootGetters }, { template, firstTemplate }) {
    if (!template) throw new Error('Missing template');

    try {
      const response = await api.rmvfy.getUserUnlayerKey();

      const user = {
        id: rootGetters['user/getUserId'],
        signature: response.signature,
      };

      commit('SET_USER', user);
      commit('SET_TEMPLATE', template);
      commit('SET_FIRST_TEMPLATE_BACKUP', firstTemplate);
    } catch (error) {
      return error;
    }
  },
};
