<template>
  <li class="nav-item" :class="{ active: isActive }">
    <a
      v-if="isMenu"
      class="sidebar-menu-item nav-link"
      :class="[{ active: isActive }, { 'parent-active': childrenLength > 0 }]"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      :title="link.name"
      @click.prevent="collapsed = !collapsed"
    >
      <i v-if="link.icon" :class="link.icon"></i>
      <!-- eslint-disable vue/no-v-html -->
      <!-- we are the source of the text -->
      <!-- Please make sure that's always the case -->
      <span class="nav-link-text"><span v-html="link.name"></span> <b class="caret"></b></span>
      <BadgeCell
        v-if="badge"
        :type="badge.type"
        :class="badge.class"
        size="sm ml-2 font-bold lh-120"
      >{{ badge.text }}</BadgeCell>
    </a>

    <div v-if="$slots.default || isMenu" class="show nav-collapse">
      <TransitionExpand :duration="500">
        <div v-show="!collapsed">
          <ul class="nav nav-sm flex-col">
            <slot></slot>
          </ul>
        </div>
      </TransitionExpand>
    </div>

    <template v-if="childrenLength === 0 && link.path">
      <component
        :is="elementType(link, false)"
        :to="link.path"
        class="nav-link"
        :class="{ active: link.active }"
        :target="link.target"
        :href="link.path"
        :title="link.name"
        @click="linkClick()"
      >
        <i v-if="link.icon" :class="link.icon"></i>
        <span class="nav-link-text">{{ link.name }}</span>
        <BadgeCell
          v-if="badge"
          :type="badge.type"
          size="sm"
          class="ml-2 font-bold"
          :class="badge.class"
        >
          {{ badge.text }}
        </BadgeCell>
      </component>
    </template>
  </li>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useSidebarStore } from './useSidebarStore';
import { TransitionExpand } from '@morev/vue-transitions';

defineOptions({
  name: 'SidebarItem',
});
const { menu, link, badge, disableActive, childrenLength } = withDefaults(
  defineProps<Props>(),
  {
    menu: false,
    link: {
      name: '',
      path: '',
      icon: '',
      active: false,
      target: '',
      isRoute: false,
      children: [],
    },
    badge: {},
    disableActive: false,
    childrenLength: 0,
  },
);
const route = useRoute();
const { showSidebar, displaySidebar } = useSidebarStore();
interface Props {
  menu?: boolean;
  link?: any;
  badge?: any;
  disableActive?: boolean;
  childrenLength?: number;
}

const BadgeCell = defineAsyncComponent(
  () => import('@/components/Argon/Badge.vue'),
);

const autoClose = inject('autoClose');

const children = ref<Array<any>>([]);
const collapsed = ref(false);

const isMenu = computed(() => {
  return childrenLength > 0 && menu === true;
});
const isActive = computed(() => {
  if (disableActive) return;

  if (route.path) {
    const matchingRoute = children.value.find(c =>
      route.path.startsWith(c.link.path),
    );
    if (matchingRoute !== undefined) {
      return true;
    }
  }
  return false;
});

const elementType = (link: any, isParent = true) => {
  if (link.isRoute === false) {
    return isParent ? 'li' : 'a';
  } else {
    return 'router-link';
  }
};

const linkClick = () => {
  if (autoClose && showSidebar.value === true) {
    displaySidebar(false);
  }
};

onMounted(() => {
  if (link.collapsed !== undefined) {
    collapsed.value = link.collapsed;
  }
  if (isActive.value && isMenu) {
    collapsed.value = false;
  }
});
onBeforeUnmount(() => {
  if (showSidebar.value) {
    showSidebar.value = false;
  }
});
</script>

<style>
.sidebar-menu-item {
  cursor: pointer;
}
</style>
