import kebabCase from 'lodash/kebabCase';
import store from '@/store';

/**
 * slugify - kebabs the string
 * @param {*} params
 */
export function slugify(params) {
  return kebabCase(params);
}

/**
 * isJsonString - checks if a string is a valid JSON string
 * @param {*} str
 */
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * wait - classic delay function
 * @param {*} ms
 */
export function wait(ms) {
  return new Promise(r => setTimeout(r, ms));
}

/**
 * resize - resets the layout with sidebar and content
 */
export function resize() {
  const menuIsOpen = JSON.parse(localStorage.getItem('_toggle_menu'));
  if (menuIsOpen === true) {
    if (window.innerWidth <= 1600) {
      if (store.state.toggleMenu !== false) {
        store.commit('SET_MENU', false);
      }
    } else {
      if (store.state.toggleMenu !== true) {
        store.commit('SET_MENU', true);
      }
    }
  }
}

/**
 * firstLetterUppercase - used for formatting the first letter of a string to uppercase
 * @param {*} str
 * @returns
 */
export function firstLetterUppercase(str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 * matchHeight - used for matching div heights
 * Alternative: you can use CSS class items-stretch with flex, and set the child divs with h-full
 * @param {*} delay
 */
export function matchHeight(delay = 500) {
  if (
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    setTimeout(() => {
      const groupName = Array.prototype.slice.call(
        document.querySelectorAll('[data-match-height]'),
      );
      const groupHeights = {};

      for (const item of groupName) {
        const data = item.getAttribute('data-match-height');
        item.style.minHeight = 'auto';

        if (Object.prototype.hasOwnProperty.call(groupHeights, data)) {
          Object.defineProperty(groupHeights, data, {
            value: Math.max(groupHeights[data], item.offsetHeight),
            configurable: true,
            writable: true,
            enumerable: true,
          });
        } else {
          groupHeights[data] = item.offsetHeight;
        }
      }

      const groupHeightsMax = groupHeights;

      Object.getOwnPropertyNames(groupHeightsMax).forEach((value) => {
        const elementsToChange = document.querySelectorAll(
          "[data-match-height='" + value + "']",
        );
        const elementsLength = elementsToChange.length;

        for (let i = 0; i < elementsLength; i++) {
          elementsToChange[i].style.minHeight =
            Object.getOwnPropertyDescriptor(groupHeightsMax, value).value +
            'px';
        }
      });
    }, delay);
  }
}

/**
 * maskEmail - masks email address
 * @param {string} email
 * @returns {string} masked email
 */

export const maskEmail = (email) => {
  // If the email is falsy, return an empty string
  if (!email) {
    return '';
  }

  const parts = email.split('@');

  // if no @ symbol, return unknown email
  if (parts.length === 1) {
    return 'Unknown';
  }
  return parts[0].substring(0, 1) + '****' + '@' + parts[1];
};

/**
 * maskAuthToken - masks auth token for sentry
 * @param {string} token
 * @returns {string} masked token
 */
export const maskAuthToken = (token) => {
  if (!token) return 'Unknown Token';
  return token.substring(0, 5) + '****' + token.substring(token.length - 5);
};

/**
 * debounce - used for debouncing a function
 * @param {Function} func
 * @param {number}wait
 * @returns
 */
export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const assignWithDefaults = (source, defaultVal) => {
  const result = {};
  // to make sure we don't mutate the default values
  const defaultValues = JSON.parse(JSON.stringify(defaultVal));

  for (const key in defaultValues) {
    if (
      defaultValues[key] !== null &&
      typeof defaultValues[key] === 'object' &&
      !Array.isArray(defaultValues[key])
    ) {
      result[key] = assignWithDefaults(
        source ? source[key] : undefined,
        defaultValues[key],
      );
    } else {
      result[key] =
        source && source[key] !== undefined ? source[key] : defaultValues[key];
    }
  }

  return result;
};

/**
 * Deep check if two objects are equal
 * @link https://stackoverflow.com/questions/25456013/javascript-deepequal-comparison
 * @author Daniil Andreyevich Baunov <https://stackoverflow.com/users/7179862/daniil-andreyevich-baunov>
 * @param {object} obj1
 * @param {object} obj2
 * @returns
 */
export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    // it's just the same object. No need to compare.
    return true;
  }

  if (isPrimitive(obj1) && isPrimitive(obj2)) {
    // compare primitives
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  // compare objects with same number of keys
  for (const key in obj1) {
    if (!(key in obj2)) return false; // other object doesn't have this prop
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

// check if value is primitive
const isPrimitive = (obj) => {
  return obj !== Object(obj);
};

export default {
  slugify,
  isJsonString,
  resize,
  wait,
  matchHeight,
  firstLetterUppercase,
  maskEmail,
  maskAuthToken,
  debounce,
  assignWithDefaults,
};

export * from './tracking';

/**
 * @typedef {object} Event
 * @property {"Remove from request"|"Add to request"|"Password updated"|"Login"|"Submit new removal request"} name - event name e.g. logged in
 * @property {*} ...rest - event data
 */
