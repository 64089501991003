export const System = [
  /**
   * @see https://router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
   */
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: '404',
      params: {
        resource: 'page',
      },
    },
  },
  {
    path: '/',
    name: 'load',
    component: () => import('@/views/Load/Index.vue'),
    props: true,
    meta: {
      title: 'Welcome to Removify',
      layout: 'Blank',
      requiresAuth: true,
      scopes: [],
    },
  },
  {
    path: '/dashboard/welcome',
    name: 'welcome.dashboard',
    component: () => import('@/views/Load/Welcome.vue'),
    props: true,
    meta: {
      title: 'Welcome to Removify',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: [],
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Error/404/Index.vue'),
    props: true,
    meta: {
      title: "Sorry! We can't find your page.",
      layout: 'Blank',
      scopes: [],
    },
  },
  {
    path: '/restricted',
    name: '401',
    component: () => import('@/views/Error/401/Index.vue'),
    props: true,
    meta: {
      title: 'Restricted Access',
      layout: 'Blank',
      scopes: [],
    },
  },
  {
    path: '/error',
    name: 'network-error',
    component: () => import('@/views/Error/500/Index.vue'),
    props: true,
    meta: {
      title: 'Something broke!',
      layout: 'Blank',
      scopes: [],
    },
  },
  {
    path: '/ds',
    name: 'design-system',
    component: () => import('@/views/DS/Index.vue'),
    props: true,
    meta: {
      title: 'Removify Design System',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['design'],
    },
  },
  {
    path: '/ds/:component',
    name: 'design-system-component',
    component: () => import('@/views/DS/Index.vue'),
    props: true,
    meta: {
      title: 'Removify Design System',
      layout: 'Dashboard',
      requiresAuth: true,
      scopes: ['design'],
    },
  },
];
