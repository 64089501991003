import { vOnClickOutside } from '@vueuse/components';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */
const GlobalDirectives = {
  install(app) {
    app.directive('clickOutside', vOnClickOutside);
  },
};

export default GlobalDirectives;
