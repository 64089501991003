import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import store from './store';
import globalMixin from './mixins/legacyFilters';
import { initGlobalOptions } from '@/utils/chart';
import DashboardPlugin from './plugins/dashboard-plugin';
import { getTrackingInitials } from '@/utils/index';
import { initSentry } from '@/plugins/sentry';
import { initMixpanel } from './plugins/mixpanel';

import './assets/css/index.css';

const pinia = createPinia();
const app = createApp(App);
app.config.productionTip = false;
app.use(DashboardPlugin, store);
app.mixin(globalMixin);

initGlobalOptions();
initSentry(app, router);
initMixpanel();

document.getElementById('app').addEventListener('userLoggedIn', (e) => {
  getTrackingInitials(e.detail.user);
}, { once: true });

app.use(router);
app.use(store);
app.use(pinia);
app.mount('#app');
