import { ZodError } from 'zod';
import type { Action, Getter, Mutation } from 'vuex';
import type {
  BaseReporting,
  Reporting,
  ReportingList,
  ReportingTemplateList,
} from '@/types/Reporting/reporting';
import type { Module, ModuleMapping } from '@/types/Reporting/module';
import { defaultModuleSetting } from '@/types/Reporting/module';
import type State from '@/types/store/reporting';
import api from '@/services';
import { useGlobalLoading } from '@/composables/globalLoading';
import type { BaseParams } from '@/types/axios';
import { assignWithDefaults } from '@/utils';

const { start, done } = useGlobalLoading();
export const namespaced = true;

export const getDefaultState = (): State => {
  return {
    reports: [],
    selectedReport: null,
    selectedReportClone: null,
    moduleMapping: [],
    totalReporting: 0,
    reportingTemplate: [],
  };
};

export const state = getDefaultState();

export const getters: {
  [key: string]: Getter<State, State>;
} = {
  getReports: (state: State) => state.reports,
  getSelectedReportModules: (state: State) => {
    if (!state.selectedReport) {
      return [];
    }
    return state.selectedReport.modules;
  },
  getSelectedReportId: (state: State) => {
    if (!state.selectedReport) {
      return null;
    }
    return state.selectedReport.reporting_id;
  },
  getTemplateToList: (state: State) => {
    return state.reportingTemplate.map((template) => {
      return {
        ...template,
        title: template.name,
      };
    });
  },
};

export const mutations: {
  [key: string]: Mutation<State>;
} = {
  SET_REPORTS(state: State, reports: ReportingList) {
    state.reports = reports;
  },
  SET_SELECTED_REPORT(state: State, report: Reporting) {
    state.selectedReport = report;
  },
  SET_SELECTED_REPORT_CLONE(state: State, report: Reporting) {
    state.selectedReportClone = report;
  },
  SET_MODULE_MAPPING(state: State, moduleMapping: ModuleMapping) {
    state.moduleMapping = moduleMapping;
  },
  SET_TOTAL_REPORTING(state: State, total: number) {
    state.totalReporting = total;
  },
  SET_REPORTING_TEMPLATE(state: State, template: ReportingTemplateList) {
    state.reportingTemplate = template;
  },
};

export const actions: {
  [key: string]: Action<State, State>;
} = {
  /**
   * Fetch all reports
   * @param startNP start global loading
   * @param page page number
   * @param limit number of items per page
   * @param end_date end date
   * @param start_date start date
   * @param order order
   * @returns
   */
  async fetchReports(
    { commit },
    {
      startNP = true,
      page,
      limit,
      end_date,
      order,
      start_date,
    }: Omit<BaseParams, 'search'> & { startNP?: boolean; },
  ) {
    if (startNP) {
      start();
    }

    const data = await api.rmvfyV3.reporting.getReporting({
      page,
      limit,
      end_date,
      order,
      start_date,
    });
    if (data.error) {
      done();
      commit('SET_REPORTS', []);
      return { error: data.error };
    }
    done();

    if (!data.data) {
      done();
      commit('SET_REPORTS', []);
      return { error: new Error('Unknown error') };
    }

    commit('SET_REPORTS', data.data.data);
    commit('SET_TOTAL_REPORTING', data.data.total);

    return { error: null };
  },

  /**
   * Select report by id
   * @param id id of the report
   * @param startNP start global loading
   * @returns
   */
  async selectReport(
    { commit },
    { id, startNP = true }: { id: number; startNP?: boolean; },
  ) {
    if (startNP) {
      start();
    }

    const data = await api.rmvfyV3.reporting.getReportById(id);
    if (data.error) {
      done();
      commit('SET_SELECTED_REPORT', null);
      return { error: data.error };
    }

    done();
    const modules = data.data?.modules;

    /**
     * @note If modules is not empty, then we need to assign default setting to each module
     * because we might add more default setting in the future
     */
    if (modules && modules.length > 0) {
      modules.forEach((module) => {
        const _setting = JSON.parse(module.setting);
        module.setting = assignWithDefaults(_setting, defaultModuleSetting);
      });
      data.data!.modules = modules;
    }

    commit('SET_SELECTED_REPORT', data.data);
    commit('SET_SELECTED_REPORT_CLONE', JSON.parse(JSON.stringify(data.data)));
    return { error: null };
  },

  /**
   * Delete report by id
   * @param id id of the report
   * @param startNP start global loading
   * @returns
   */
  async deleteReport(
    { commit, dispatch },
    { id, startNP = true }: { id: number; startNP?: boolean; },
  ) {
    if (startNP) {
      start();
    }
    const data = await api.rmvfyV3.reporting.deleteReportById(id);
    if (data.error) {
      done();
      return { error: data.error };
    }
    done();
    commit('SET_SELECTED_REPORT', null);
    dispatch('fetchReports', false);
    return { error: null };
  },

  /**
   * Duplicate report by id
   * @param id id of the report
   * @param startNP start global loading
   * @returns
   */
  async duplicateReport(
    { commit, dispatch },
    { id, startNP = true }: { id: number; startNP?: boolean; },
  ) {
    if (startNP) {
      start();
    }
    const data = await api.rmvfyV3.reporting.cloneReportById(id);
    if (data.error) {
      done();
      // If the error is zod error, then we need to push the data to the top of the list
      // as the data is already cloned
      if (data.error instanceof ZodError) {
        commit('SET_SELECTED_REPORT', null);
        // push data.data to the top of the list
        dispatch('addReport', data.data!);
      }
      return { error: data.error };
    }

    if (!data.data) {
      done();
      return { error: new Error('Unknown error') };
    }
    done();
    commit('SET_SELECTED_REPORT', null);
    // push data.data to the top of the list
    dispatch('addReport', data.data);

    return { error: null };
  },

  /**
   * Rename report
   * @param id id of the report
   * @param name new name of the report
   * @param startNP start global loading
   * @returns
   */
  async renameReport(
    { dispatch },
    {
      id,
      name,
      startNP = true,
    }: { id: number; name: string; startNP?: boolean; },
  ) {
    if (startNP) {
      start();
    }
    const data = await api.rmvfyV3.reporting.updateReportById(id, { name });
    if (data.error) {
      done();
      return { error: data.error };
    }
    if (!data.data) {
      done();
      return { error: new Error('Unknown error') };
    }
    dispatch('updateCurrentReport', { id, report: data.data });
    done();
    return { error: null };
  },

  /**
   * Set Module Mapping
   * @returns
   */
  async getModuleMapping({ commit }) {
    const data = await api.rmvfyV3.reporting.getModulesMapping();
    if (data.error) {
      commit('SET_MODULE_MAPPING', []);
      return { error: data.error };
    }
    commit('SET_MODULE_MAPPING', data.data);
    return { error: null };
  },

  /**
   * Set Reporting Template
   * @returns
   */
  async getReportingTemplate({ commit }) {
    const data = await api.rmvfyV3.reporting.getReportingTemplate();
    if (data.error) {
      commit('SET_REPORTING_TEMPLATE', []);
      return { error: data.error };
    }
    commit('SET_REPORTING_TEMPLATE', data.data);
    return { error: null };
  },

  /**
   * Reset selected report
   */
  resetSelectedReport({ commit }) {
    commit('SET_SELECTED_REPORT', null);
  },

  /**
   * Add report to the top of the list
   * @param report
   */
  addReport({ state }, report: Reporting) {
    state.reports.unshift(report);
  },

  /**
   * Update one report in the list
   * @param id id of the report
   * @report report to update
   */
  updateCurrentReport(
    { state },
    { id, report }: { id: number; report: BaseReporting; },
  ) {
    const index = state.reports.findIndex(r => r.reporting_id === id);
    if (index !== -1) {
      state.reports.splice(index, 1, report);
    }
  },

  /**
   * Add module to selected report
   * @param module
   * @returns
   */
  addModuleToSelectedReport({ state }, module: Module) {
    if (!state.selectedReport) {
      return;
    }
    state.selectedReport.modules.push(module);
  },

  /**
   * Reset selected report from clone, this function is used for undoing the changes
   */
  resetSelectedReportFromClone({ commit, state }) {
    commit(
      'SET_SELECTED_REPORT',
      JSON.parse(JSON.stringify(state.selectedReportClone)),
    );
  },

  /**
   * Remove module from selected report
   * @param reporting_module_id
   * @returns
   */
  removeModuleFromSelectedReport({ state }, reporting_module_id: number) {
    if (!state.selectedReport) {
      return;
    }
    const index = state.selectedReport.modules.findIndex(
      m => m.reporting_module_id === reporting_module_id,
    );
    if (index !== -1) {
      state.selectedReport.modules.splice(index, 1);
    }
  },
};
