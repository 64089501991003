export const namespaced = true;

export const getDefaultState = () => {
  return {
    content: '',
    component: '',
    htmlTitle: '',
    title: '',
    customData: null, // used to pass data to the modal

    // type
    showProgressBar: false,
    confirmation: {
      enabled: false,
      actions: {
        cancel: null,
        ok: null,
      },
    },

    // appearance
    size: '',
    contentClasses: '',
    classes: '',
    gapless: false,
    headerClasses: '',
    headless: false,

    // behavior
    clickOutside: true,
    outsideCloseMessage: '',

    // control
    showClose: true,
    isVisible: false,

    // Note: String mutation that will be called when the modal is closed [module/MUTATION]
    onCloseMutation: null,
  };
};

export const state = getDefaultState();

export const mutations = {
  SET_CUSTOM_DATA(state, obj) {
    state.customData = obj;
  },
  SET_VISIBILITY(state, bool) {
    state.isVisible = bool;
  },
  SET_MODAL_TITLE(state, title) {
    state.title = title;
  },
  SET_MODAL_HTML_TITLE(state, htmlTitle) {
    state.htmlTitle = htmlTitle;
  },
  SET_MODAL_CONTENT(state, content) {
    state.content = content;
  },
  SET_MODAL_CONFIRMATION(state, bool) {
    state.confirmation.enabled = bool;
  },
  SET_MODAL_CONFIRMATION_OK(state) {
    state.confirmation.actions.ok = true;
  },
  SET_MODAL_CONFIRMATION_CANCEL(state) {
    state.confirmation.actions.cancel = true;
  },
  SET_MODAL_COMPONENT(state, content) {
    state.component = content;
  },
  SET_PROGRESS_BAR(state, bool) {
    state.showProgressBar = bool;
  },
  SET_GAPLESS(state, bool) {
    state.gapless = bool;
  },
  SET_SHOW_CLOSE(state, bool) {
    state.showClose = bool;
  },
  SET_CLICK_OUTSIDE(state, bool) {
    state.clickOutside = bool;
  },
  SET_OUTSIDE_CLOSE_MESSAGE(state, message) {
    state.outsideCloseMessage = message;
  },
  SET_WINDOW_SIZE(state, size) {
    state.windowSize.width = size.width ? Number.parseInt(size.width) : 'auto';
    state.windowSize.height = size.height
      ? Number.parseInt(size.height)
      : 'auto';
  },
  SET_SIZE(state, size) {
    state.size = size;
  },
  SET_MODAL_CONTENT_CLASSES(state, classes) {
    state.contentClasses = classes;
  },
  SET_MODAL_CLASSES(state, classes) {
    state.classes = classes;
  },
  SET_HEADER_CLASSES(state, classes) {
    state.headerClasses = classes;
  },
  SET_HEADLESS(state, value) {
    state.headless = value;
  },
  SET_ON_CLOSE_MUTATION(state, value) {
    state.onCloseMutation = value;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  setModal({ commit }, modal) {
    if (Object.prototype.hasOwnProperty.call(modal, 'visibility')) {
      commit('SET_VISIBILITY', modal.visibility);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'content')) {
      commit('SET_MODAL_CONTENT', modal.content);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'component')) {
      commit('SET_MODAL_COMPONENT', modal.component);
    }

    if (modal.confirmation && modal.confirmation.enabled === true) {
      commit('SET_MODAL_CONFIRMATION', true);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'gapless')) {
      commit('SET_GAPLESS', modal.gapless);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'showClose')) {
      commit('SET_SHOW_CLOSE', modal.showClose);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'title')) {
      commit('SET_MODAL_TITLE', modal.title);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'htmlTitle')) {
      commit('SET_MODAL_HTML_TITLE', modal.htmlTitle);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'classes')) {
      commit('SET_MODAL_CLASSES', modal.classes);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'contentClasses')) {
      commit('SET_MODAL_CONTENT_CLASSES', modal.contentClasses);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'customData')) {
      commit('SET_CUSTOM_DATA', modal.customData);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'clickOutside')) {
      commit('SET_CLICK_OUTSIDE', modal.clickOutside);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'outsideCloseMessage')) {
      commit('SET_OUTSIDE_CLOSE_MESSAGE', modal.outsideCloseMessage);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'showProgressBar')) {
      commit('SET_PROGRESS_BAR', modal.showProgressBar);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'size')) {
      commit('SET_SIZE', modal.size);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'headerClasses')) {
      commit('SET_HEADER_CLASSES', modal.headerClasses);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'headless')) {
      commit('SET_HEADLESS', modal.headless);
    }

    if (Object.prototype.hasOwnProperty.call(modal, 'onCloseMutation')) {
      commit('SET_ON_CLOSE_MUTATION', modal.onCloseMutation);
    }
  },
  updateModalTitle({ commit }, data) {
    if (data.title) {
      commit('SET_MODAL_TITLE', data.title);
    }

    if (data.htmlTitle) {
      commit('SET_MODAL_HTML_TITLE', data.htmlTitle);
    }
  },
  updateCustomData({ commit }, data) {
    if (data) {
      commit('SET_CUSTOM_DATA', data);
    }
  },
};
